import { Layout } from "antd";
import React from "react";

export const BaseFooter = ({ paddingTop = "0vh" }: { paddingTop?: string }) => (
  <Layout.Footer>
    <div style={{ paddingTop, textAlign: "center" }}>
      Copyright © {new Date().getFullYear()} Spiral Technologies Inc. · All rights reserved
    </div>
    <div style={{ textAlign: "center" }}>
      <a href="https://www.spiralup.co/terms-of-use">Terms of Use</a> ·{" "}
      <a href="https://www.spiralup.co/privacy-policy">Privacy Policy</a> ·{" "}
      <a href="https://www.spiralup.co/contact">Contact Us</a>
    </div>
  </Layout.Footer>
);

export const Footer = BaseFooter;
