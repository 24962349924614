import { CopyOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { AmplifyProvider, Authenticator, Text, Theme } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Button, Divider, Layout, Menu, Space, Spin } from "antd";
import { Amplify, Auth } from "aws-amplify";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.less";
import awsconfig from "./aws-exports";
import "./body.css";
import ErrorBoundary from "./components/ErrorBoundary";
import { BaseFooter, Footer } from "./components/Footer";
import { BaseHeader, CustomerImage } from "./components/Header";
import { SpiralMenu } from "./components/SpiralMenu";
import {
  customerContext,
  CustomerUIModel,
  isAdmin,
  useCurrentUser,
  useCustomer,
  useCustomerGroups,
} from "./hooks";
import { ReportAPIResp } from "./indexTypes";
import logo from "./logo_blue.svg";
import { ClusterFilterPage } from "./pages/admin/ClusterFilterPage";
import { CustomerManagerPage } from "./pages/admin/CustomerManagerPage";
import { DatasetPage } from "./pages/admin/DatasetPage";
import { DatasetsPage } from "./pages/admin/DatasetsPage";
import { DemoManagerPage } from "./pages/admin/DemoManagerPage";
import { KPIPage } from "./pages/admin/KPIPage";
import { TitlesPageWrapper } from "./pages/admin/TitlesPage";
import { UserPage } from "./pages/admin/UserPage";
import { UsersPage } from "./pages/admin/UsersPage";
import { ChooseCustomer } from "./pages/ChooseCustomer";
import { MainPage } from "./pages/MainPage";
import { UnauthorizedUser } from "./pages/UnauthorizedUser";
import { getReports } from "./reportApi";
import { truncate } from "./utils";

moment.tz.setDefault("America/Los_Angeles");
moment.locale("en-us", {
  week: {
    dow: 1,
  },
});

Amplify.configure(awsconfig);

ReactGA.initialize("UA-172918356-1", {
  testMode: process.env.NODE_ENV === "test",
  debug: process.env.NODE_ENV === "development" && process.env.DEBUG_GA === "true",
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});

const AppLayout = ({
  reports,
  children,
}: {
  reports?: ReportAPIResp;
  children?: React.ReactNode;
}) => {
  const { customer, unsetCustomer } = useCustomer();
  const user = useCurrentUser();
  const [jwt, setJwt] = useState<string>();

  useEffect(() => {
    const f = async () => {
      setJwt((await Auth.currentSession()).getAccessToken().getJwtToken());
    };
    if (process.env.NODE_ENV !== "production") {
      f();
    }
  }, []);

  return (
    <Layout
      style={{
        height: "100%",
      }}
    >
      {/* <Header /> */}
      <Layout.Sider theme="light">
        <a href="/">
          <Space direction="vertical" className="logowrapper">
            <img src={logo} alt="" height="80px" id="logo" />
            <Divider className="logodivider" />
            <CustomerImage customer={customer} />
          </Space>
        </a>
        <ErrorBoundary>
          <SpiralMenu
            customer={customer}
            unsetCustomer={unsetCustomer}
            admin={isAdmin(user)}
            reports={reports}
          />
        </ErrorBoundary>
        <Menu key="logout-menu" id="logout" mode="vertical">
          <Menu.SubMenu
            key="logout-submenu"
            title={
              <span>
                <UserOutlined />
                {truncate(user ? (user as any).attributes.email : "Spiral User", 20)}
              </span>
            }
            popupOffset={[0, process.env.NODE_ENV !== "production" ? -50 : 0]}
          >
            <Menu.Item key="logout-item">
              <Button type="link" icon={<LogoutOutlined />} onClick={() => Auth.signOut()} danger>
                Log out
              </Button>
            </Menu.Item>
            {process.env.NODE_ENV !== "production" && (
              <Menu.Item key="logout-jwt">
                <Button
                  type="link"
                  icon={<CopyOutlined />}
                  onClick={() => jwt && navigator.clipboard.writeText(jwt)}
                >
                  Copy JWT
                </Button>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        </Menu>
      </Layout.Sider>
      <Layout style={{ marginLeft: 227 }}>
        <Layout.Content
          style={{
            minHeight: "90vh",
          }}
        >
          {children}
        </Layout.Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

const RequireAdmin = () => {
  const user = useCurrentUser();
  if (!isAdmin(user)) {
    return <div>Unauthorized</div>;
  }
  return <Outlet />;
};

const ProvideCustomer = ({
  children,
  value,
}: {
  value: { customer: CustomerUIModel; customers: CustomerUIModel[]; unsetCustomer: () => void };
  children?: React.ReactNode;
}) => <customerContext.Provider value={value}>{children}</customerContext.Provider>;

const AppWithRoutes = ({
  customer,
  customers,
  unsetCustomer,
}: {
  customer: CustomerUIModel;
  customers: CustomerUIModel[];
  unsetCustomer: () => void;
}) => {
  const location = useLocation();
  const [reports, setReports] = useState<ReportAPIResp>();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    const getReportsAsync = async () => {
      const resp = await getReports(customer.id);
      setReports(resp);
    };
    getReportsAsync();
  }, []);

  return (
    <ProvideCustomer value={{ customer, customers, unsetCustomer }}>
      <AppLayout reports={reports}>
        <Routes>
          <Route path="/admin" element={<RequireAdmin />}>
            <Route path="demos" element={<DemoManagerPage customers={customers} />} />
            <Route path="titles">
              <Route
                index
                element={<Navigate to={`${customer.index.defaultReportSet}`} replace />}
              />
              <Route
                path=":reportId"
                element={<TitlesPageWrapper reports={reports} admin={true} />}
              />
            </Route>
            <Route path="customers">
              <Route index element={<CustomerManagerPage />} />
              <Route path=":customerId/customer" element={<CustomerManagerPage />} />
              <Route
                path=":customerId/reports"
                element={<CustomerManagerPage tabDefault={"report-editor"} />}
              />
              <Route
                path=":customerId/titles"
                element={<CustomerManagerPage tabDefault={"titles-editor"} />}
              />
              <Route
                path=":customerId/clusters"
                element={<CustomerManagerPage tabDefault={"clusters-editor"} />}
              />
            </Route>
            <Route path="users">
              <Route index element={<UsersPage />} />
              <Route path=":userId" element={<UserPage />} />
            </Route>
            <Route path="datasets">
              <Route index element={<DatasetsPage />} />
              <Route path=":datasetUuid" element={<DatasetPage />} />
            </Route>
            <Route path="cluster-filterer" element={<ClusterFilterPage />} />
            <Route path="kpis" element={<KPIPage />} />
          </Route>

          <Route path="/dashboards">
            <Route
              index
              element={<Navigate to={`/dashboards/${customer.index.defaultReportSet}`} replace />}
            />
            <Route path=":reportId" element={<MainPage reports={reports} />} />
          </Route>

          <Route key="index" path="*" element={<Navigate to={`/dashboards`} replace />} />
        </Routes>
      </AppLayout>
    </ProvideCustomer>
  );
};

const theme: Theme = {
  name: "my-theme",
  tokens: {
    colors: {
      background: {
        primary: { value: "#FBFBFB" },
      },
      brand: {
        primary: {
          "10": { value: "#465FC31A" },
          "80": { value: "#465FC3" },
          "90": { value: "#465FC3CC" },
          "100": { value: "#465FC3" },
        },
      },
      font: {
        primary: {
          value: "#363F4A",
        },
      },
    },
    shadows: {
      medium: {
        value: {
          offsetX: "0",
          offsetY: "0",
          blurRadius: "29px",
          color: "rgba(12, 15, 50, 0.09)",
        },
      },
    },
  },
};

export const App = () => {
  const currentUser = useCurrentUser();

  if (currentUser) {
    const email = (currentUser as any).attributes.email;
    ReactGA.set({
      userId: email,
      dimension1: email.substring(email.lastIndexOf("@") + 1), // domain dimension
    });
  }
  const { customer, customers, setCustomer, unsetCustomer, isLoading } = useCustomerGroups();

  return (
    <AmplifyProvider theme={theme}>
      {currentUser ? null : <BaseHeader />}
      <Router>
        <Authenticator
          components={{
            ConfirmResetPassword: {
              Header() {
                return (
                  <>
                    <Authenticator.ResetPassword.Header />
                    <Text variation="primary">A code has been sent to your email.</Text>
                  </>
                );
              },
            },
          }}
        >
          <Spin spinning={isLoading}>
            {!isLoading && customers.length === 0 && <UnauthorizedUser currentUser={currentUser} />}
            {!isLoading && !customer && customers.length > 1 && (
              <ChooseCustomer
                currentUser={currentUser}
                customers={customers}
                setCustomer={setCustomer}
                isLoading={isLoading}
              />
            )}
            {(customer || customers.length === 1) && (
              <AppWithRoutes
                customer={customer ? customer : customers[0]}
                customers={customers}
                unsetCustomer={unsetCustomer}
              />
            )}
          </Spin>
        </Authenticator>
        {currentUser ? null : <BaseFooter />}
      </Router>
    </AmplifyProvider>
  );
};
