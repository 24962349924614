import { Auth } from "aws-amplify";
import moment from "moment";
import { CustomerUIModel } from "./hooks";
import {
  APIError,
  Customer,
  CustomerIndex,
  DatasetFilterCounts,
  Filter,
  Period,
  Report,
  ReportDatasetInclude,
  ReportIndex,
  ReportIndexAudit,
  ReportWindow,
  Sentiment,
  User,
} from "./indexTypes";
import { Example, MetadataField, SortableClusterSummary, Titles } from "./reports";
import { sum } from "./utils";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const bearerToken = () =>
  Auth.currentSession().then(session => `Bearer ${session.getIdToken().getJwtToken()}`);

const fetchWithReject = (input: RequestInfo | URL, init?: RequestInit): Promise<Response> =>
  fetch(input, init).then(res => {
    if (!res.ok) {
      res.json().then(console.error);
      throw Error(`Request rejected with status ${res.status}`);
    }
    return res;
  });

export const customerIndexes = async (signal?: AbortSignal): Promise<CustomerUIModel[]> =>
  fetchWithReject(`${endpoint}/customer_indexes`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: await bearerToken(),
    },
    mode: "cors",
    signal,
  }).then(r => r.json());

export interface APIParams {
  customer: string;
  filters: Filter[];
  window: ReportWindow;
  latestLength: number;
  datasets: ReportDatasetInclude[];
}

interface DBReportAPI {
  metadata: (
    signal: AbortSignal,
    params: APIParams,
    field: string,
    limit: number
  ) => Promise<MetadataField>;
  clusterMetadata: (
    signal: AbortSignal,
    params: APIParams,
    field: string,
    clusters: string[],
    limit: number
  ) => Promise<MetadataField>;
  clusters: (
    signal: AbortSignal,
    params: APIParams,
    reportIndexId: string,
    showDisabled: boolean,
    sentimentFilter: Sentiment | "all"
  ) => Promise<{ clusters: SortableClusterSummary[]; superclusters: SortableClusterSummary[] }>;
  evidences: (
    signal: AbortSignal,
    params: APIParams,
    cluster: string,
    bypassCache?: boolean
  ) => Promise<Example[]>;
  total: (signal: AbortSignal, params: APIParams) => Promise<{ total: number }>;
  timeStart: (signal: AbortSignal, params: APIParams) => Promise<string>;
  analyzeByOptions: (signal: AbortSignal, params: APIParams) => Promise<string[]>;
  stat: (signal: AbortSignal, params: APIParams) => Promise<number>;
  dayCounts: (signal: AbortSignal, params: APIParams) => Promise<MetadataField>;
  bounds: (
    signal: AbortSignal,
    params: APIParams
  ) => Promise<{ min: string; max: string; filterless_min: string; filterless_max: string }>;
}

class APIImpl implements DBReportAPI {
  private api = endpoint + "/overview";

  buildParams = (func: string, params: APIParams, extra?: { [key: string]: string | string[] }) =>
    new URLSearchParams({
      ...{
        func,
        customer: params.customer,
        date: params.window.end,
        freq: params.window.interval,
        periods: params.window.periods.toString(),
        datasets: JSON.stringify(params.datasets),
        filters: JSON.stringify(params.filters),
      },
      ...extra,
    }).toString();

  get = async (
    signal: AbortSignal,
    func: string,
    params: APIParams,
    extra?: { [key: string]: string | string[] },
    bypassCache = false
  ) =>
    fetchWithReject(`${this.api}?${this.buildParams(func, params, extra)}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: await bearerToken(),
      },
      cache: bypassCache ? "reload" : "default",
      mode: "cors",
      signal,
    }).then(r => r.json());

  timeStart = (_signal: AbortSignal, params: APIParams) =>
    new Promise<string>(resolve =>
      resolve(
        moment(params.window.end)
          .subtract(1, params.window.interval as Period)
          .toISOString()
      )
    );

  analyzeByOptions = () => new Promise<string[]>(resolve => resolve(["FIXME"]));

  dayCounts = async (signal: AbortSignal, params: APIParams) =>
    this.get(signal, "dayCounts", params) as Promise<MetadataField>;

  stat = async (signal: AbortSignal, params: APIParams) =>
    (await this.dayCounts(signal, params)).data.map(d => d.count).reduce(sum, 0);

  metadata = async (signal: AbortSignal, params: APIParams, field: string, limit: number) =>
    this.get(signal, "metadata", params, { field, analyzeByLimit: limit.toString() });

  clusterMetadata = async (
    signal: AbortSignal,
    params: APIParams,
    field: string,
    clusterIds: string[],
    limit: number
  ) =>
    this.get(signal, "metadata", params, {
      field,
      cluster: clusterIds,
      analyzeByLimit: limit.toString(),
    });

  clusters = async (
    signal: AbortSignal,
    params: APIParams,
    reportIndexId: string,
    showDisabled: boolean,
    sentimentFilter: Sentiment | "all"
  ) =>
    this.get(signal, "clusters", params, {
      reportIndexId,
      sentimentFilter,
      showDisabled: showDisabled.toString(),
    });

  evidences = async (
    signal: AbortSignal,
    params: APIParams,
    cluster: string,
    bypassCache = false
  ) => this.get(signal, "evidences", params, { cluster }, bypassCache);

  total = async (signal: AbortSignal, params: APIParams) => this.get(signal, "total", params);

  bounds = async (signal: AbortSignal, params: APIParams) => this.get(signal, "bounds", params);
}

export const API: DBReportAPI = new APIImpl();

export const headers = async () => {
  const session = await Auth.currentSession();
  return {
    "Content-Type": "application/json",
    "Cache-Control": "public",
    Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
  };
};

export const updateCustomerIndex = async (
  id: string,
  index: CustomerIndex
): Promise<CustomerIndex | APIError> => {
  const session = await Auth.currentSession();
  return fetchWithReject(`${endpoint}/customer_index/${id}`, {
    method: "POST",
    body: JSON.stringify(index),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
    },
    mode: "cors",
  }).then(r => r.json());
};

export const getReportIndex = async (signal: AbortSignal, id: string) =>
  fetchWithReject(`${endpoint}/report_index/${id}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
    signal,
  }).then(r => r.json() as unknown as ReportIndex);

export const updateReportIndex = async (id: string, index: ReportIndex) =>
  fetchWithReject(`${endpoint}/report_index/${id}`, {
    method: "POST",
    body: JSON.stringify(index),
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const renameReport = async (
  id: string,
  newName: string
): Promise<{ auditLog: ReportIndexAudit[] } | APIError> =>
  fetchWithReject(`${endpoint}/report_index/${id}/rename/${newName}`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const setReportTitles = async (
  reportId: string,
  titlesId: string
): Promise<{ auditLog: ReportIndexAudit[] } | APIError> =>
  fetchWithReject(`${endpoint}/report_index/${reportId}/set_titles/${titlesId}`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getReportIndexAudit = async (
  signal: AbortSignal,
  id: string
): Promise<{ auditLog: ReportIndexAudit[] } | APIError> =>
  fetchWithReject(`${endpoint}/report_index/${id}/audit`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
    signal,
  }).then(r => r.json());

export const getTitlesFile = async (signal: AbortSignal, id: string) =>
  fetchWithReject(`${endpoint}/titles_file/${id}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
    signal,
  }).then(r => r.json() as unknown as Titles);

export const updateTitlesFile = async (id: string, titles: Titles) =>
  fetchWithReject(`${endpoint}/titles_file/${id}`, {
    method: "POST",
    body: JSON.stringify(titles),
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const datasetsOverview = async () =>
  fetchWithReject(`${endpoint}/datasets/overview`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export type TimeBuckets = "month" | "year";
export const datasetsByBucket = async (
  bucket: TimeBuckets,
  filteredCustomers: string[],
  filteredSource: string[]
) =>
  fetchWithReject(
    `${endpoint}/bucketed_datasets?bucket=${bucket}&customers=${filteredCustomers}&sources=${filteredSource}`,
    {
      method: "GET",
      headers: await headers(),
      mode: "cors",
    }
  ).then(r => r.json());

export const sources = async (customers: string[]) =>
  fetchWithReject(`${endpoint}/sources?customers=${customers}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getTitlesFiles = async (customer: string) =>
  fetchWithReject(`${endpoint}/customer/${customer}/titles`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getReports = async (customer: string) =>
  fetchWithReject(`${endpoint}/customer/${customer}/reports`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const createReport = async (
  customer: string,
  reportName: string,
  report: ReportIndex
): Promise<Report | APIError> =>
  fetchWithReject(`${endpoint}/customer/${customer}/reports/${reportName}`, {
    method: "POST",
    headers: await headers(),
    body: JSON.stringify(report),
    mode: "cors",
  }).then(r => r.json());

type Cluster = {
  id: string;
  customerId: string;
  title?: string;
  defaultTitle: string;
  sentiment?: Sentiment;
  enabled?: boolean;
};

export type ClustersResponse = {
  clusters: Cluster[];
  training_data: { [cluster_id: string]: { id: string; text: string }[] };
};

export const getCustomerClusters = async (customer: string): Promise<ClustersResponse> =>
  fetchWithReject(`${endpoint}/customer/${customer}/clusters`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getCustomerClustersExamples = async (customer: string, clusterId?: string) =>
  fetchWithReject(`${endpoint}/customer/${customer}/clusters/examples/${clusterId}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getUsers = async (): Promise<{ users: User[] }> =>
  fetchWithReject(`${endpoint}/users`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getUser = async (userId: string) =>
  fetchWithReject(`${endpoint}/users/${userId}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const updateUser = async (user: User) =>
  fetchWithReject(`${endpoint}/users/${user.cognitoSub}`, {
    method: "PUT",
    headers: await headers(),
    body: JSON.stringify(user),
    mode: "cors",
  }).then(r => r.json());

export const getClustersPage = async (customer_id: string) =>
  fetchWithReject(`${endpoint}/clusters/${customer_id}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getClassifiers = async () =>
  fetchWithReject(`${endpoint}/cluster-studio`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getClassifier = async (id: string) =>
  fetchWithReject(`${endpoint}/cluster-studio/classifier-models/${id}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getClusters = async () =>
  fetchWithReject(`${endpoint}/cluster-studio/clusters`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getCluster = async (id: string) =>
  fetchWithReject(`${endpoint}/cluster-studio/clusters/${id}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const setClusterTitle = async (id: string, title: string) =>
  fetchWithReject(`${endpoint}/clusters/${id}/title`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify({ title }),
  });

export const getCustomers = async (): Promise<{ customers: Customer[] }> =>
  fetchWithReject(`${endpoint}/customers`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const removeEvidence = async (clusteredTextId: string) =>
  fetchWithReject(`${endpoint}/remove_evidence/${clusteredTextId}`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const moveEvidence = async (clusteredTextId: string, toCluster: string) => {
  return fetchWithReject(`${endpoint}/move_evidence/${clusteredTextId}?to_cluster=${toCluster}`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());
};

export type Judgement = "positive" | "negative";

interface AnnotationResponse {
  annotation: {
    id?: string;
    judgement: Judgement;
  };
}

export const annotateEvidence = async (
  clusteredTextId: string,
  judgement?: Judgement
): Promise<AnnotationResponse> => {
  let url = `${endpoint}/annotate_evidence/${clusteredTextId}`;
  if (judgement) {
    url += `?judgement=${judgement}`;
  }
  return fetchWithReject(url, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());
};

export const getDataset = async (datasetUuid: string) =>
  fetchWithReject(`${endpoint}/datasets/${datasetUuid}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getDatasetFilter = async (
  datasetUuid: string,
  filterKey?: string,
  filterValue?: string,
  omitFilter?: string[],
  includeFilter?: string[],
  size?: number
): Promise<APIError | DatasetFilterCounts> => {
  const params = new URLSearchParams();
  if (omitFilter) {
    omitFilter.forEach(filter => params.append("omit", filter));
  }
  if (includeFilter) {
    includeFilter.forEach(filter => params.append("include", filter));
  }
  if (size) {
    params.append("size", size.toString());
  }
  if (filterKey && filterValue) {
    params.append("filter_by", `${filterKey}:${filterValue}`);
  }

  return fetchWithReject(`${endpoint}/datasets/${datasetUuid}/filters?` + params, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());
};

export const getExample = async (type: string, field: string) =>
  fetchWithReject(`${endpoint}/admin/examples?type=${type}&field=${field}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getKPIs = async (days: number) =>
  fetchWithReject(`${endpoint}/admin/kpis/${days}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());
