import React, { useEffect, useState } from "react";

import { Card, Col, Row, Spin, Statistic, Table, Typography } from "antd";
import Column from "antd/lib/table/Column";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Area,
  AreaChart,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { datasetsOverview } from "../../reportApi";
import { sum } from "../../utils";

const { Text } = Typography;

interface DatasetsOverview {
  datasets: string[];
  totals: [{ dataset_name: string; dataset_uuid: string; count: number }];
  recentBreakdown: [
    { date: string; dataset: string; customer: string; source: string; count: number }
  ];
}

const piecolors = ["#223065", "#4558A5", "#7080C2", "#B3BDE7", "#DBE0F5"];

export const DatasetsPage = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DatasetsOverview>();

  useEffect(() => {
    const f = async () => {
      setLoading(true);
      try {
        setData(await datasetsOverview());
      } finally {
        setLoading(false);
      }
    };
    f();
  }, []);

  return (
    <Spin spinning={loading}>
      <Row style={{ margin: "12px 24px 12px" }} gutter={10}>
        <Col span={4}>
          <Card>
            <Statistic
              title="# Datasets"
              value={new Set(data?.totals.map(v => v.dataset_name)).size}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card>
            <Statistic title="# Conversations" value={data?.totals.map(v => v.count).reduce(sum)} />
          </Card>
        </Col>
      </Row>
      <Row style={{ margin: "0 24px 12px" }} gutter={10}>
        <Col span={18}>
          <Card title="New Conversations Added per Day">
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={data?.recentBreakdown}>
                <XAxis dataKey="date" tickFormatter={x => moment(x).format("D MMM")} />
                <YAxis />
                {data?.datasets.map((dataset, idx) => (
                  <Area
                    key={dataset}
                    dataKey={dataset}
                    stackId={1}
                    fill={piecolors[idx % piecolors.length]}
                  />
                ))}
                <Tooltip formatter={(value: any) => value.toLocaleString()} />
              </AreaChart>
            </ResponsiveContainer>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Dataset Sizes">
            <ResponsiveContainer height={300}>
              <PieChart>
                <Tooltip formatter={(value: any) => value.toLocaleString()} />
                <Pie data={data?.totals} dataKey="count" nameKey="dataset">
                  {data &&
                    data.totals.map((entry, index) => (
                      <Cell key={entry.dataset_name} fill={piecolors[index % piecolors.length]} />
                    ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
      <Row style={{ margin: "0 24px 12px" }} gutter={10}>
        <Col span={24}>
          <Table
            rowKey="dataset_uuid"
            dataSource={loading || !data ? [] : data.totals}
            pagination={false}
          >
            <Column
              key="uuid"
              title="Dataset UUID"
              dataIndex="dataset_uuid"
              sorter={(a: any, b: any) => a.dataset_uuid.localeCompare(b.dataset_uuid)}
              render={value => (
                <Text copyable={{ text: value, format: "text/plain" }}>
                  <Link to={value}>{value}</Link>
                </Text>
              )}
            />
            <Column
              key="dataset"
              title="Dataset Name"
              dataIndex="dataset_name"
              sorter={(a: any, b: any) => a.dataset_name.localeCompare(b.dataset_name)}
              defaultSortOrder="ascend"
            />
            <Column
              key="count"
              title="Count"
              dataIndex="count"
              align="right"
              render={val => val.toLocaleString()}
              sorter={(a: any, b: any) => a.count - b.count}
            />
          </Table>
        </Col>
      </Row>
    </Spin>
  );
};
