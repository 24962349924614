import { EditOutlined, PlusOutlined, SaveFilled, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Divider,
  Input,
  notification,
  Radio,
  Row,
  Space,
  Spin,
  Tabs,
  Tooltip,
  Typography,
  Upload
} from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { InputStatus } from "antd/lib/_util/statusUtils";
import _ from "lodash";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { invalidateCloudFrontLogoURI, uploadStaticAsset } from "../../demoApi";
import typesjson from "../../gen/generatedTypes.json";
import { useCustomer } from "../../hooks";
import {
  Customer,
  CustomerIndex,
  isAPIError,
  ReportAPIResp,
  ReportIndex,
  ReportIndexAudit,
  TypeDocInfo
} from "../../indexTypes";
import {
  ClustersResponse,
  createReport,
  getCustomerClusters,
  getCustomerClustersExamples,
  getCustomers,
  getExample,
  getReportIndexAudit,
  getReports,
  getTitlesFiles,
  renameReport,
  setReportTitles,
  updateCustomerIndex,
  updateReportIndex
} from "../../reportApi";
import { Titles } from "../../reports";
import { TitlesPage } from "./TitlesPage";

const fetchExample = async (
  type: string,
  field: string,
  setExamples: React.Dispatch<React.SetStateAction<{ [key: string]: string } | undefined>>,
  examples?: { [key: string]: string }
) => {
  try {
    const resp = await getExample(type, field);
    if (isAPIError(resp)) {
      const newExamples = { ...examples, [field]: `ERROR: ${resp.description}` };
      setExamples(newExamples);
    } else {
      const newExamples = { ...examples, [field]: JSON.stringify(resp, null, 2) };
      setExamples(newExamples);
    }
  } catch (error) {
    const newExamples = { ...examples, [field]: `ERROR: ${error}` };
    setExamples(newExamples);
  }
};

const UploadableCustomerImage = ({
  customer,
  customerIndex,
  setCustomerIndexText,
}: {
  customer: Customer;
  customerIndex: CustomerIndex;
  setCustomerIndexText: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const [imgsrc, setImgSrc] = useState<string>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    customer;
    setImgSrc(`https://static.spiralup.co/${customer.customerIndexJson.logo}`);
  }, [customer, customerIndex]);

  const uploadCustomerLogo = async (options: any) => {
    const { onSuccess, onError, file } = options;
    setLoading(true);
    const dest = `${customer.id}/logo.png`;
    try {
      uploadStaticAsset(file, dest);
      invalidateCloudFrontLogoURI(dest);
    } catch (e) {
      notification.open({ message: `Failure saving logo!`, duration: 5 });
      onError(e);
      setLoading(false);
      return;
    }
    notification.open({ message: `logo saved to s3!`, duration: 5 });
    onSuccess("success");

    customerIndex.logo = dest;
    setCustomerIndexText(JSON.stringify(customerIndex, null, 2));
    await updateCustomerIndex(customer.id, customerIndex);

    setLoading(false);
  };

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      customRequest={uploadCustomerLogo}
    >
      <Spin spinning={loading}>
        <img src={imgsrc} alt="logo" style={{ width: 80, maxWidth: 80, maxHeight: 40 }} />
      </Spin>
    </Upload>
  );
};

function getDifference(o1: object, o2: object) {
  const added: Array<keyof object> = [];
  const removed: Array<keyof object> = [];
  const changed: Array<keyof object> = [];

  (Object.keys(o1) as Array<keyof object>).forEach(k => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (k in o1 && !(k in o2)) {
      added.push(k);
    } else if (JSON.stringify(o1[k]) !== JSON.stringify(o2[k])) {
      changed.push(k);
    }
  });
  (Object.keys(o2) as Array<keyof object>).forEach(k => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!(k in o1) && k in o2) {
      removed.push(k);
    }
  });
  const result = { added: "", changed: "", removed: "" };
  if (added.length) {
    result.added = JSON.stringify(added, null, 2);
  }
  if (changed.length) {
    result.changed = JSON.stringify(changed, null, 2);
  }
  if (removed.length) {
    result.removed = JSON.stringify(removed, null, 2);
  }

  return result;
}

const criticalFields = ["datasets", "baseQueryFilters"];
const filtersFields = ["filters"];
const analyzeByFields = ["defaultAnayzeBy", "analyzeByLimit", "analyzeByOptions"];

const format = (obj: object) => JSON.stringify(obj, null, 2);

export const ReportEditor = ({
  customer,
  reports,
  setReports,
}: {
  customer?: Customer;
  reports?: ReportAPIResp;
  setReports: Dispatch<SetStateAction<ReportAPIResp | undefined>>;
}) => {
  const [selectedReportIndex, setSelectedReportIndex] = useState<string>();
  const [reportIndexText, setReportIndexText] = useState<string>();
  const [reportIndex, setReportIndex] = useState<ReportIndex>();
  const [oldreportIndex, setOldReportIndex] = useState<ReportIndex>();

  const [reportIndexTextVaild, setReportIndexTextValid] = useState(false);

  const [filtersText, setFiltersText] = useState<string>();
  const [analyzeByText, setAnalyzeByText] = useState<string>();
  const [criticalText, setCriticalText] = useState<string>();
  const [mainFieldsText, setMainFieldsText] = useState<string>();

  const [reportIndexLoading, setReportIndexLoading] = useState(false);

  const [newReportName, setNewReportName] = useState<string>();
  const [newReportStatus, setNewReportStatus] = useState<InputStatus>("");

  const [reportName, setReportName] = useState<string>();
  const [titlesId, setTitlesId] = useState<string>();

  const [titlesFiles, setTitlesFiles] = useState<{ [id: string]: TitlesFile }>();

  const [badProps, setBadProps] = useState<string[]>([]);
  const [jsonDelta, setJsonDelta] = useState<{ added: string; changed: string; removed: string }>();
  const [reportAudit, setReportAudit] = useState<ReportIndexAudit[]>();
  const [examples, setExamples] = useState<{ [key: string]: string }>();

  useEffect(() => {
    const f = async () => {
      if (customer) {
        setTitlesFiles(await getTitlesFiles(customer.id));
      }
    };
    if (customer) {
      setSelectedReportIndex(customer.customerIndexJson.defaultReportSet);
    }
    f();
  }, [customer]);

  useEffect(() => {
    if (reportIndex && oldreportIndex) {
      setJsonDelta(getDifference(reportIndex, oldreportIndex));
    } else {
      setJsonDelta(undefined);
    }
  }, [reportIndex, oldreportIndex]);

  const indexProps = typesjson.children.find(t => t.name == "ReportIndex")?.type?.declaration
    ?.children as TypeDocInfo[];

  useEffect(() => {
    if (reportIndex) {
      const badStuff: string[] = [];
      for (const prop in reportIndex) {
        if (!indexProps.some(p => p.name == prop)) {
          badStuff.push(prop);
        }
      }
      setBadProps(badStuff);
    }
  }, [reportIndex, indexProps]);

  // Load the content of the report editor
  useEffect(() => {
    const controller = new AbortController();
    const f = async () => {
      if (customer && reports && selectedReportIndex && selectedReportIndex in reports) {
        setReportIndexLoading(true);
        try {
          const rptIdx = reports[selectedReportIndex].reportIndexJson;
          setReportName(reports[selectedReportIndex].name);
          setTitlesId(reports[selectedReportIndex].titleId.toString());
          setReportIndex(rptIdx);
          setOldReportIndex(rptIdx);
          setReportIndexText(format(rptIdx));
          setMainFieldsText(
            format(_.omit(rptIdx, ...[...criticalFields, ...analyzeByFields, ...filtersFields]))
          );
          setFiltersText(format(_.pick(rptIdx, ...filtersFields)));
          setAnalyzeByText(format(_.pick(rptIdx, ...analyzeByFields)));
          setCriticalText(format(_.pick(rptIdx, ...criticalFields)));
          setReportIndexTextValid(true);
        } catch (e) {
          if (controller.signal.aborted) {
            console.log("Abort received. User likely chose a new customer.");
          } else {
            console.error("The request failed");
          }
        } finally {
          if (!controller.signal.aborted) {
            setReportIndexLoading(false);
          }
        }
      }
    };

    f();
    return () => controller.abort();
  }, [reports, selectedReportIndex, customer]);

  useEffect(() => {
    if (!selectedReportIndex || !reports || !(selectedReportIndex in reports)) {
      return;
    }
    const controller = new AbortController();
    const f = async () => {
      const resp = await getReportIndexAudit(controller.signal, reports[selectedReportIndex].id);
      if (isAPIError(resp)) {
        return;
      }
      setReportAudit(resp.auditLog);
    };

    f();
    return () => controller.abort();
  }, [reports, selectedReportIndex]);

  const saveReportIndex = async () => {
    if (!customer || !reports || !selectedReportIndex || !reportIndex || !reportIndexText) {
      return;
    }
    try {
      JSON.parse(reportIndexText);
    } catch {
      notification.error({
        message: `Invalid JSON. Not saving!`,
        duration: 5,
      });
      return;
    }
    setReportIndexLoading(true);
    try {
      const resp = await updateReportIndex(reports[selectedReportIndex].id, reportIndex);
      if (isAPIError(resp)) {
        notification.error({
          message: `Failed to save!! ${resp.description}`,
          duration: 5,
        });
        return;
      }

      reports[selectedReportIndex].reportIndexJson = resp;
      setOldReportIndex(resp);

      notification.success({
        message: `Saved!`,
        duration: 1,
      });
    } catch {
      notification.error({
        message: `Failed to save!!`,
        duration: 5,
      });
    } finally {
      setReportIndexLoading(false);
    }
  };

  const onRenameReport = async () => {
    if (selectedReportIndex && reports && reportName) {
      await renameReport(reports[selectedReportIndex].id, reportName);
      window.location.reload();
    }
  };

  const onChangeTitles = async () => {
    if (selectedReportIndex && reports && titlesId) {
      await setReportTitles(reports[selectedReportIndex].id, titlesId);
      window.location.reload();
    }
  };

  // Reflects valid (parsable) changes from the user edited text to the real ReportIndex object. Makes sure that the
  // real object doesn't contain invalid json.
  useEffect(() => {
    try {
      setReportIndex({
        ...JSON.parse(mainFieldsText ?? "{}"),
        ...JSON.parse(analyzeByText ?? "{}"),
        ...JSON.parse(filtersText ?? "{}"),
        ...JSON.parse(criticalText ?? "{}"),
      });
      setReportIndexTextValid(true);
    } catch (error) {
      console.error(error);
      setReportIndexTextValid(false);
    }
  }, [mainFieldsText, analyzeByText, filtersText, criticalText]);

  const addNewReportIndex = async () => {
    if (!customer || !reports) {
      console.error("Customer or reports not set. Cannot create a reportIndex.");
      return;
    }
    if (!newReportName) {
      setNewReportStatus("error");
      return;
    }
    setNewReportStatus("");

    const newReport: ReportIndex = {
      period: "week",
      periodLength: 12,
      latestLength: 1,
      datasets: [{ uuid: "TODO" }],
      overviewLegendText: "REPLACE ME",
      datePicker: {
        amount: 12,
        unit: "week",
        snapTo: {
          direction: "end",
          unit: "week",
        },
      },
      filters: {},
      areaCurveType: "basis",
      minTickGap: 100,
      xTickFormat: "D MMM 'YY",
      showDateButtons: false,
    };

    try {
      const resp = await createReport(customer.id, newReportName, newReport);
      if (isAPIError(resp)) {
        console.error(resp.error, resp.description);
        notification.error({
          className: "selectable",
          message: `Failure creating report! ${resp.description}`,
          duration: 10,
        });
        return;
      }
      notification.open({
        message: `Report created!`,
        duration: 5,
      });

      setReports({ ...reports, ...{ [resp.urlHash]: resp } });
      setSelectedReportIndex(resp.urlHash);
    } catch (err) {
      console.error(err);
      notification.error({
        message: `Report API failure! ${err}`,
        className: "selectable",
        duration: 10,
      });
    }
  };

  return (
    <>
      <Row key="cm-editor" className="selectable" gutter={32}>
        <Col span={12}>
          <h3>Report Set Editor</h3>
          <Row style={{ margin: "0 0 12px" }}>
            <Col>
              {reports && (
                <Radio.Group
                  value={selectedReportIndex}
                  size="large"
                  onChange={e => {
                    setSelectedReportIndex(e.target.value);
                  }}
                >
                  {Object.entries(reports).map(([key, val]) => (
                    <Radio.Button key={key} value={key}>
                      {`${val.name}`}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              )}
            </Col>
          </Row>
          {reportIndex && (
            <>
              <Row style={{ margin: "0 0 12px" }} justify="space-between">
                <Col>
                  <Space>
                    <Input.Group compact>
                      <Input
                        style={{ width: "calc(100% - 100px)" }}
                        placeholder="report_name"
                        allowClear={true}
                        status={newReportStatus}
                        onChange={e => setNewReportName(e.target.value)}
                      />
                      <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        onClick={() => addNewReportIndex()}
                      >
                        New
                      </Button>
                    </Input.Group>
                  </Space>
                </Col>
              </Row>
              <Row style={{ margin: "0 0 12px" }} justify="space-between">
                <Col>
                  <Space>
                    <Input.Group compact>
                      <Input
                        style={{ width: "calc(100% - 120px)" }}
                        allowClear={true}
                        value={reportName}
                        onChange={e => setReportName(e.target.value)}
                      />
                      <Button
                        icon={<EditOutlined />}
                        type="primary"
                        disabled={
                          !(
                            selectedReportIndex &&
                            reports &&
                            selectedReportIndex in reports &&
                            reportName !== reports[selectedReportIndex].name
                          )
                        }
                        onClick={() => onRenameReport()}
                      >
                        Rename
                      </Button>
                    </Input.Group>
                  </Space>
                </Col>
              </Row>
              <Row style={{ margin: "0 0 12px" }} justify="space-between">
                <Col>
                  <Space>
                    <Input.Group compact>
                      <Input
                        style={{ width: "calc(100% - 160px)" }}
                        allowClear={true}
                        value={titlesId}
                        onChange={e => setTitlesId(e.target.value)}
                      />
                      <Button
                        icon={<EditOutlined />}
                        type="primary"
                        disabled={
                          !(
                            selectedReportIndex &&
                            reports &&
                            selectedReportIndex in reports &&
                            titlesId &&
                            titlesId !== reports[selectedReportIndex].titleId.toString() &&
                            titlesFiles &&
                            titlesId in titlesFiles
                          )
                        }
                        onClick={() => onChangeTitles()}
                      >
                        Change Titles
                      </Button>
                    </Input.Group>
                  </Space>
                </Col>
              </Row>
              <Row style={{ margin: "0 0 12px" }}>
                <Col>
                  <Space size="large">
                    <Space>
                      <Typography.Text>Report Set Id:</Typography.Text>
                      <Typography.Text code copyable>
                        {selectedReportIndex}
                      </Typography.Text>
                    </Space>
                    <Space>
                      <Typography.Text>Titles Id:</Typography.Text>
                      <Typography.Text code copyable>
                        {reports &&
                          selectedReportIndex &&
                          selectedReportIndex in reports &&
                          reports[selectedReportIndex].titleId}
                      </Typography.Text>{" "}
                    </Space>
                  </Space>
                </Col>
              </Row>
              <Row style={{ margin: "0 0 12px" }}>
                <Col>
                  <Spin spinning={reportIndexLoading}>
                    <span>Main Fields:</span>
                    <Input.TextArea
                      value={mainFieldsText}
                      onChange={e => {
                        setMainFieldsText(e.target.value);
                      }}
                      autoSize={{ minRows: 10, maxRows: 40 }}
                    />
                    <span>Analyze By:</span>
                    <Input.TextArea
                      value={analyzeByText}
                      onChange={e => {
                        setAnalyzeByText(e.target.value);
                      }}
                      autoSize={{ minRows: 5, maxRows: 20 }}
                    />
                    <span>Filters:</span>
                    <Input.TextArea
                      value={filtersText}
                      onChange={e => {
                        setFiltersText(e.target.value);
                      }}
                      autoSize={{ minRows: 5, maxRows: 20 }}
                    />
                    <span>Danger Zone:</span>
                    <Input.TextArea
                      value={criticalText}
                      onChange={e => {
                        setCriticalText(e.target.value);
                      }}
                      autoSize={{ minRows: 10, maxRows: 40 }}
                      status="error"
                    />
                  </Spin>
                </Col>
              </Row>
            </>
          )}
        </Col>
        <Col span={12}>
          <div style={{ position: "sticky", top: "60px" }}>
            <h3>
              Report Validator:{" "}
              {reports &&
                selectedReportIndex &&
                selectedReportIndex in reports &&
                reports[selectedReportIndex].name}
            </h3>
            {badProps.length ? (
              <>
                <Divider orientation="left">Unknown Properties</Divider>
                <pre className={"admin-text-box admin-editor-col"} style={{ color: "red" }}>
                  {JSON.stringify(badProps, null, 2)}
                </pre>
              </>
            ) : null}
            {jsonDelta && (jsonDelta.added || jsonDelta.changed || jsonDelta.removed) ? (
              <>
                <Divider orientation="left">Diff</Divider>
                <h4>Added</h4>
                <pre className={"admin-text-box admin-editor-col"} style={{ color: "green" }}>
                  {jsonDelta.added}
                </pre>
                <h4>Changed</h4>
                <pre className={"admin-text-box admin-editor-col"} style={{ color: "orange" }}>
                  {jsonDelta.changed}
                </pre>
                <h4>Removed</h4>
                <pre className={"admin-text-box admin-editor-col"} style={{ color: "red" }}>
                  {jsonDelta.removed}
                </pre>
              </>
            ) : null}
            <Button
              icon={<SaveFilled />}
              type="primary"
              onClick={() => saveReportIndex()}
              disabled={!reportIndexTextVaild}
            >
              Save
            </Button>
            <Divider orientation="left">Titles</Divider>
            <pre>
              {titlesFiles &&
                Object.keys(titlesFiles)
                  .sort((a, b) => titlesFiles[a].name.localeCompare(titlesFiles[b].name))
                  .map(k => "\n" + titlesFiles[k].name + ": " + k)}{" "}
            </pre>
            <Divider orientation="left">Documentation</Divider>
            <div className={"admin-text-box admin-editor-col"}>
              {indexProps.map(prop => (
                <div style={{ paddingTop: "6px" }} key={"type-" + prop.name}>
                  <h3>
                    {prop.name}{" "}
                    <Tooltip title="search">
                      <Button
                        onClick={() => fetchExample("report", prop.name!, setExamples, examples)}
                        shape="circle"
                        icon={<SearchOutlined />}
                        size="small"
                      />
                    </Tooltip>
                  </h3>
                  {prop.comment && (
                    <div
                      style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", padding: "0 0.5em" }}
                    >
                      {prop.comment.summary?.map(s => s.text)}
                    </div>
                  )}
                  {examples && prop.name && prop.name in examples && (
                    <pre>{examples[prop.name]}</pre>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
      <Collapse>
        <Collapse.Panel key="audit-top" header="Audit Log">
          <Collapse>
            {reportAudit && reportAudit.length > 0
              ? reportAudit.map(r => {
                  return (
                    <Collapse.Panel
                      className="selectable"
                      key={`audit-${r.id}`}
                      header={
                        <>
                          <Link to={"../../users/" + r.updatedBy}>User: {r.userName}</Link>
                          <span> - updated at: {r.updatedAt} </span>
                        </>
                      }
                    >
                      <Typography.Text copyable>
                        <pre>{JSON.stringify(r.reportIndexJson)}</pre>
                      </Typography.Text>
                    </Collapse.Panel>
                  );
                })
              : null}
          </Collapse>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

export const CustomerEditor = ({
  customer,
  updateCustomerState,
  reports,
}: {
  customer?: Customer;
  updateCustomerState: (id: string, index: CustomerIndex) => void;
  reports?: ReportAPIResp;
}) => {
  const [customerIndexLoading, setCustomerIndexLoading] = useState(false);
  const [customerIndexText, setCustomerIndexText] = useState<string>();
  const [customerIndex, setCustomerIndex] = useState<CustomerIndex>();
  const [oldCustomerIndex, setOldCustomerIndex] = useState<CustomerIndex>();
  const [badProps, setBadProps] = useState<string[]>([]);
  const [jsonDelta, setJsonDelta] = useState<{ added: string; changed: string; removed: string }>();
  const [examples, setExamples] = useState<{ [key: string]: string }>();

  useEffect(() => {
    if (customer) {
      setCustomerIndex(customer.customerIndexJson);
      setOldCustomerIndex(customer.customerIndexJson);
      setCustomerIndexText(JSON.stringify(customer.customerIndexJson, null, 2));
    }
  }, [customer]);

  // Reflects valid (parsable) changes from the user edited text to the real CustomerIndex object. Makes sure that the
  // real object doesn't contain invalid json.
  useEffect(() => {
    if (customerIndexText) {
      try {
        setCustomerIndex(JSON.parse(customerIndexText));
      } catch (error) {
        setJsonDelta(undefined);
      }
    }
  }, [customerIndexText]);

  useEffect(() => {
    if (customerIndex && oldCustomerIndex) {
      setJsonDelta(getDifference(customerIndex, oldCustomerIndex));
    } else {
      setJsonDelta(undefined);
    }
  }, [customerIndex, oldCustomerIndex]);

  const saveCustomerIndex = async () => {
    if (!customer || !customerIndex || !customerIndexText) return;
    try {
      JSON.parse(customerIndexText);
    } catch {
      notification.error({
        message: `Invalid JSON. Not saving!`,
        duration: 5,
      });
      return;
    }
    setCustomerIndexLoading(true);
    try {
      const resp = await updateCustomerIndex(customer.id, customerIndex);
      if (isAPIError(resp)) {
        notification.error({
          message: `Failed to save!! ${resp.description}`,
          duration: 5,
        });
        return;
      }
      updateCustomerState(customer.id, resp);
      setOldCustomerIndex(resp);
      notification.success({
        message: `Saved!`,
        duration: 1,
      });
    } catch (err) {
      console.error(err);
      notification.error({
        message: `Failed to save!!`,
        duration: 5,
      });
    } finally {
      setCustomerIndexLoading(false);
    }
  };

  const indexProps = typesjson.children.find(t => t.name == "CustomerIndex")?.type?.declaration
    ?.children as TypeDocInfo[];

  useEffect(() => {
    if (customerIndex) {
      const badStuff: string[] = [];
      for (const prop in customerIndex) {
        if (!indexProps.some(p => p.name == prop)) {
          badStuff.push(prop);
        }
      }
      setBadProps(badStuff);
    }
  }, [customerIndex, indexProps]);

  return (
    <Row key="cm-editor" className="selectable" gutter={32}>
      <Col span={12}>
        <h3>Customer Editor</h3>
        <Spin spinning={!customer || customerIndexLoading}>
          {customer && customerIndex && (
            <>
              <Row style={{ margin: "0 0 24px" }} justify="space-between">
                <Col>
                  <Space>
                    <UploadableCustomerImage
                      customer={customer}
                      customerIndex={customerIndex}
                      setCustomerIndexText={setCustomerIndexText}
                    />
                  </Space>
                </Col>
              </Row>
              <Row style={{ margin: "0 0 12px" }}>
                <Col>
                  <Space>
                    <Typography.Text>Customer ID:</Typography.Text>
                    <Typography.Text code copyable>
                      {customer.id}
                    </Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Spin spinning={customerIndexText === undefined}>
                {customerIndexText && (
                  <Input.TextArea
                    value={customerIndexText}
                    onChange={e => {
                      setCustomerIndexText(e.target.value);
                    }}
                    autoSize={{ minRows: 10, maxRows: 40 }}
                  />
                )}
              </Spin>
            </>
          )}
        </Spin>
      </Col>
      <Col span={12}>
        <h3>Customer Validator</h3>
        {badProps.length ? (
          <>
            <Divider orientation="left">Unknown Properties</Divider>
            <pre className={"admin-text-box admin-editor-col"} style={{ color: "red" }}>
              {JSON.stringify(badProps, null, 2)}
            </pre>
          </>
        ) : null}
        {jsonDelta && (jsonDelta.added || jsonDelta.changed || jsonDelta.removed) ? (
          <>
            <Divider orientation="left">Diff</Divider>
            <h4>Added</h4>
            <pre className={"admin-text-box admin-editor-col"} style={{ color: "green" }}>
              {jsonDelta.added}
            </pre>
            <h4>Changed</h4>
            <pre className={"admin-text-box admin-editor-col"} style={{ color: "orange" }}>
              {jsonDelta.changed}
            </pre>
            <h4>Removed</h4>
            <pre className={"admin-text-box admin-editor-col"} style={{ color: "red" }}>
              {jsonDelta.removed}
            </pre>
          </>
        ) : null}
        <Button icon={<SaveFilled />} type="primary" onClick={() => saveCustomerIndex()}>
          Save
        </Button>
        <Divider orientation="left">Reports</Divider>
        <pre>
          {reports &&
            Object.keys(reports)
              .sort((a, b) => reports[a].name.localeCompare(reports[b].name))
              .map(k => "\n" + reports[k].name + ": " + k)}{" "}
        </pre>
        <Divider orientation="left">Documentation</Divider>
        <div className={"admin-text-box admin-editor-col"}>
          {indexProps.map(prop => (
            <div style={{ paddingTop: "6px" }} key={"type-" + prop.name}>
              <h3>
                {prop.name}{" "}
                <Tooltip title="search">
                  <Button
                    onClick={() => fetchExample("customer", prop.name!, setExamples, examples)}
                    shape="circle"
                    icon={<SearchOutlined />}
                    size="small"
                  />
                </Tooltip>
              </h3>
              {prop.comment && (
                <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                  &ensp;{prop.comment.summary?.map(s => s.text).join("<br/>")}{" "}
                </div>
              )}
              {examples && prop.name && prop.name in examples && <pre>{examples[prop.name]}</pre>}
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};

interface TitlesFile {
  id: string;
  name: string;
  titles_json: Titles;
  customer_id: string;
}

const TitlesFileTab = ({ customer, reports }: { customer?: Customer; reports?: ReportAPIResp }) => {
  const [titlesFiles, setTitlesFiles] = useState<{ [id: string]: TitlesFile }>();
  const [titlesFileId, setTitlesFileId] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const f = async () => {
      if (!customer) return;
      setIsLoading(true);
      setTitlesFiles(await getTitlesFiles(customer.id));
      setIsLoading(false);
    };
    if (!customer || !reports) return;
    f();
    if (customer.customerIndexJson.defaultReportSet in reports) {
      setTitlesFileId(reports[customer.customerIndexJson.defaultReportSet].titleId);
    }
  }, [customer, reports]);

  if (!customer || !reports) return null;

  return (
    <>
      <Spin spinning={isLoading}>
        <Row style={{ margin: "0 0 24px" }}>
          <Col>
            <Radio.Group
              value={titlesFileId}
              defaultValue={titlesFileId}
              size="large"
              onChange={e => setTitlesFileId(e.target.value)}
            >
              {titlesFiles &&
                Object.entries(titlesFiles).map(([key, report]) => (
                  <Radio.Button key={key} value={key}>
                    {`${report.name}:${key}`}
                  </Radio.Button>
                ))}
            </Radio.Group>
          </Col>
        </Row>
        {titlesFileId && <TitlesPage titlesFileId={titlesFileId} />}
      </Spin>
    </>
  );
};

const ClustersTab = ({ customer }: { customer: Customer }) => {
  const [clusters, setClusters] = useState<ClustersResponse["clusters"]>([]);
  const [training_data, setTrainingData] = useState<ClustersResponse["training_data"]>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExamples, setIsLoadingExamples] = useState(false);
  const [activeCluster, setActiveCluster] = useState<string>();
  const [activeClusterExamples, setActiveClusterExamples] = useState<
    { id: string; text: string }[]
  >([]);

  useEffect(() => {
    const f = async () => {
      setIsLoading(true);
      const response = await getCustomerClusters(customer.id);
      setClusters(response.clusters);
      setTrainingData(response.training_data);
      setIsLoading(false);
    };
    f();
  }, [customer]);

  useEffect(() => {
    const f = async () => {
      setIsLoadingExamples(true);
      const resp = await getCustomerClustersExamples(customer.id, activeCluster);
      setActiveClusterExamples(resp.examples);
      setIsLoadingExamples(false);
    };

    if (activeCluster) {
      f();
    } else {
      setActiveClusterExamples([]);
    }
  }, [customer, activeCluster]);
  return (
    <>
      <Spin spinning={isLoading}>
        <Row style={{ margin: "0 0 24px" }}>
          <Col span={12}>
            <div>{clusters.length} clusters</div>
            <Collapse
              accordion
              onChange={key =>
                Array.isArray(key) ? setActiveCluster(key[0]) : setActiveCluster(key)
              }
            >
              {clusters.map(c => (
                <CollapsePanel
                  key={c.id}
                  header={`${c.id} - ${c.title ?? c.defaultTitle}${
                    c.enabled === false ? " - DISABLED" : ""
                  }`}
                >
                  Training Data:
                  <ul>
                    {c.id in training_data ? (
                      training_data[c.id].map(td => <li key={td.id}>{td.text}</li>)
                    ) : (
                      <li>NO DATA</li>
                    )}
                  </ul>
                </CollapsePanel>
              ))}
            </Collapse>
          </Col>
          <Col
            span={12}
            style={{
              padding: "0 20px",
              top: "20px",
              position: "sticky",
              maxHeight: "100vh",
              overflow: "auto",
            }}
          >
            <br /> Active Cluster: {activeCluster} <br /> Live Evidences: <br />
            <Spin spinning={isLoadingExamples}>
              <ul>
                {activeClusterExamples.map(e => (
                  <li key={e.id}>{e.text}</li>
                ))}
              </ul>
            </Spin>
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export const CustomerManagerPage = ({
  tabDefault,
}: {
  tabDefault?: "customer-editor" | "report-editor" | "titles-editor" | "clusters-editor";
}) => {
  const { customer: authCustomer } = useCustomer();
  const { customerId } = useParams();

  const [customers, setCustomers] = useState<Customer[]>();
  const [customer, setCustomer] = useState<Customer>();
  const [reports, setReports] = useState<ReportAPIResp>();

  const location = useLocation();
  const navigate = useNavigate();

  const updateCustomerState = (id: string, index: CustomerIndex) => {
    if (!customers) {
      return;
    }
    const newCustomers = [...customers];
    const toUpdate = newCustomers.find((c: Customer) => c.id == id);
    if (!toUpdate) {
      console.error(`cannot find customer ${id} to update!`);
      return;
    }
    toUpdate.customerIndexJson = index;
    setCustomers(newCustomers);
  };

  // "initial"/first useEffect to fetch reports for a customer
  useEffect(() => {
    if (!customer) {
      return;
    }

    const getReportsAsync = async () => {
      const resp = await getReports(customer.id);
      setReports(resp);
    };
    getReportsAsync();
  }, [customer]);

  // Customer selection && navigation logic
  useEffect(() => {
    if (!customerId && authCustomer.id) {
      navigate(authCustomer.id);
    }
    if (customers && customerId) {
      setCustomer(customers.find(c => c.id.toLowerCase() === customerId.toLowerCase()));
    }
  }, [customerId, customers, authCustomer.id, navigate]);

  useEffect(() => {
    const f = async () => {
      getCustomers()
        .then(resp => {
          if (isAPIError(resp)) {
            console.error(`fetching customers: ${resp.description}`);
            return;
          }
          setCustomers(resp.customers);
        })
        .catch(err => {
          console.error(`fetching customers: ${err}`);
          return;
        });
    };

    f();
  }, [customerId]);

  if (!customer) return <div>Loading...</div>;

  const tabItems = [
    {
      label: "Customer",
      key: "customer-editor",
      children: (
        <CustomerEditor
          customer={customer}
          updateCustomerState={updateCustomerState}
          reports={reports}
        ></CustomerEditor>
      ),
    },
    {
      label: "Reports",
      key: "report-editor",
      children: (
        <ReportEditor customer={customer} reports={reports} setReports={setReports}></ReportEditor>
      ),
    },
    {
      label: "Titles",
      key: "titles-editor",
      children: <TitlesFileTab customer={customer} reports={reports} />,
    },
    {
      label: "Clusters",
      key: "clusters-editor",
      children: <ClustersTab customer={customer} />,
    },
  ];

  const handleTabChange = (activeKey: string) => {
    if (activeKey == "titles-editor" && !location.pathname.endsWith("titles")) {
      navigate(`/admin/customers/${customerId}/titles`);
    }
    if (activeKey == "report-editor" && !location.pathname.endsWith("reports")) {
      navigate(`/admin/customers/${customerId}/reports`);
    }
    if (activeKey == "clusters-editor" && !location.pathname.endsWith("clusters")) {
      navigate(`/admin/customers/${customerId}/clusters`);
    }
    if (activeKey == "customer-editor") {
      navigate(`../${customerId}/customer`, { state: { customerId: customerId } });
    }
  };

  return (
    <>
      <Row key="cm-spacer" style={{ margin: "0 24px 24px" }}>
        <Col />
      </Row>
      <Row key="cm-title" style={{ margin: "0 24px 24px" }}>
        <Col span={24}>
          <h3>Customer Management</h3>
        </Col>
      </Row>
      <Row key="cm-radios" style={{ margin: "0 24px 24px" }}>
        <Spin spinning={!customers}>
          <Col span={24}>
            <Radio.Group
              value={customer}
              size="large"
              onChange={e => {
                setCustomer(e.target.value);
                navigate(`../${e.target.value.id}/customer`);
              }}
            >
              {customers &&
                customers
                  .sort((a, b) => a.id.localeCompare(b.id))
                  .map(c => (
                    <Tooltip key={c.id} title={c.id}>
                      <Radio.Button key={c.id} value={c}>
                        {c.customerIndexJson.displayName}
                      </Radio.Button>
                    </Tooltip>
                  ))}
            </Radio.Group>
          </Col>
        </Spin>
      </Row>
      <Divider />
      <Row style={{ margin: "0 24px 24px" }}>
        <Col span={24}>
          <Tabs
            items={tabItems}
            onChange={handleTabChange}
            defaultActiveKey={tabDefault}
            size="large"
          />
        </Col>
      </Row>
    </>
  );
};
