import { CopyOutlined } from "@ant-design/icons";
import { Button, Col, Input, notification, Row, Select, Space, Switch, Table } from "antd";
import { TablePaginationConfig } from "antd/lib/table";
import Column from "antd/lib/table/Column";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useParams } from "react-router";
import { AdminReportList } from "../../components/AdminComponents";
import { useCustomer } from "../../hooks";
import { ReportAPIResp } from "../../indexTypes";
import { getTitlesFile, updateTitlesFile } from "../../reportApi";
import { ClusterInfo, SuperclusterInfo, Titles } from "../../reports";
import { displayName } from "../../utils";

const { Option } = Select;
const categories = ["product", "cx"];

export const TitlesPage = ({ titlesFileId }: { titlesFileId: string }) => {
  const { customer } = useCustomer();
  const [titles, setTitles] = useState<Titles>();
  const [saving, setSaving] = useState(false);
  const [chosenSet, setChosenSet] = useState<string>();
  const [showSuperClusterTable, setShowSuperClusterTable] = useState<boolean>(true);
  const [titlesLoading, setTitlesLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!chosenSet) {
      setChosenSet(customer.index.defaultReportSet);
    }
  }, [customer, chosenSet]);

  useEffect(() => {
    const controller = new AbortController();

    const loadTitles = async () => {
      setTitlesLoading(true);
      try {
        const titlesFile = await getTitlesFile(controller.signal, titlesFileId);
        setTitles(titlesFile);
      } finally {
        setTitlesLoading(false);
      }
      return () => {
        controller.abort();
      };
    };
    if (chosenSet) {
      loadTitles();
      return () => controller.abort();
    }
  }, [customer, chosenSet, titlesFileId]);

  const loading = titlesLoading;

  const save = async () => {
    setSaving(true);
    try {
      if (titles) {
        await updateTitlesFile(titlesFileId, titles);
        notification.open({
          message: `Saved ${displayName(chosenSet!)}!`,
          duration: 1,
        });
        window.location.reload();
      }
    } finally {
      setSaving(false);
    }
  };

  const addSupercluster = () => {
    if (titles) {
      const newid = (Math.random() + 1).toString(36).substring(2);
      setTitles({
        ...titles,
        ...{ superclusters: { ...titles.superclusters, [newid]: { id: newid } } },
      });
    }
  };

  return (
    <div>
      {/* <Row style={{ margin: "0 24px 24px" }}>
        <Col>
          <Space>
            <AntTooltip
              title={() => (
                <span>
                  Download titles.json
                  <br />
                  This downloads the latest <b>saved</b> titles. Reload first to make sure
                  you&apos;re in sync.
                </span>
              )}
              placement="right"
            >
              <a
                href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(titles))}`}
                target="_blank"
                rel="noreferrer"
                download={`${customer.id}_${chosenSet}_titles.json`}
              >
                <Button icon={<CloudDownloadOutlined />} />
              </a>
            </AntTooltip>

            <Upload
              name="file"
              showUploadList={false}
              beforeUpload={file => {
                const reader = new FileReader();
                reader.onerror = () => {
                  reader.abort();
                };
                reader.onload = () => {
                  // putS3File(filename, JSON.parse(reader.result as string)).then(() =>
                  //   window.location.reload()
                  // );
                };
                reader.readAsText(file);
                return false;
              }}
            >
              <AntTooltip
                title={() => (
                  <span>
                    Upload titles.json
                    <br />
                    This overwrites the saved titles. <b>You cannot undo this!!</b>
                    <br />
                    The page will reload afterwards for the titles to take effect.
                  </span>
                )}
                placement="right"
              >
                <Button disabled={true} icon={<CloudUploadOutlined />} />
              </AntTooltip>
            </Upload>
          </Space>
        </Col>
      </Row> */}
      <Row style={{ margin: "0 0 24px" }}>
        <Col span={24}>
          <Space size="large">
            <Button type="primary" onClick={save} loading={saving} disabled={loading}>
              Save
            </Button>
            <Col span={24} style={{ textAlign: "right" }}>
              <Space size="large">
                <Space size="middle">
                  <span>Show Super Cluster Table:</span>
                  <Switch checked={showSuperClusterTable} onChange={setShowSuperClusterTable} />
                </Space>
              </Space>
            </Col>
          </Space>
        </Col>
      </Row>
      <Row className="selectable">
        <Col span={24}>
          {showSuperClusterTable && (
            <SuperClusterTable
              saving={saving}
              loading={loading}
              titles={titles}
              addSupercluster={addSupercluster}
            />
          )}
          {titles && <ClusterTable loading={loading} titles={titles} chosenSet={chosenSet} />}
        </Col>
      </Row>
    </div>
  );
};

const SuperClusterTable = ({
  loading,
  saving,
  titles,
  addSupercluster,
}: {
  loading: boolean;
  saving: boolean;
  titles?: Titles;
  addSupercluster: () => void;
}) => {
  const defaultPerPage = 10;
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSizeOptions: ["10", "25", "50", "100"],
    showSizeChanger: true,
    pageSize: 10,
    current: 1,
    defaultPageSize: defaultPerPage,
  });

  function handleAddSupercluster() {
    setPagination({
      ...pagination,
      current: Math.ceil(
        (Object.keys(titles?.superclusters ?? []).length + 1) /
          (pagination.pageSize ?? defaultPerPage)
      ),
    });
    addSupercluster();
  }

  return (
    <>
      <Button type="primary" onClick={handleAddSupercluster} disabled={loading || saving}>
        Add Supercluster
      </Button>
      <Table
        dataSource={titles ? Object.values(titles.superclusters ?? {}) : []}
        pagination={pagination}
        size="small"
        loading={loading}
        onChange={(page, _pageSize) => setPagination({ ...page })}
        rowKey={record => record.id}
      >
        <Column
          title="#"
          key="index"
          dataIndex="index"
          width={5}
          render={(_val, _record, index) => `${index + 1}`}
        />
        <Column title="Super Cluster" key="supercluster" dataIndex="id" width={120} />
        <Column
          title="Title"
          key="title"
          dataIndex="title"
          render={(val, record) => (
            <Input
              defaultValue={val}
              onChange={e => {
                (record as SuperclusterInfo).title = e.target.value;
              }}
            />
          )}
        />
        <Column
            title="Category"
            key="category"
            dataIndex="category"
            render={(val, record) => (
              <div>
                <Select
                  style={{ width: 100 }}
                  defaultValue={val}
                  onChange={value => {
                    (record as ClusterInfo).category = value !== "" ? value : undefined;
                  }}
                  dropdownMatchSelectWidth={false}
                >
                  <Option value="">-</Option>
                  {categories.map(category => (
                    <Option key={category} value={category}>
                      {category}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          />
        <Column
          title="Enabled"
          key="enabled"
          dataIndex="enabled"
          width={60}
          render={(val, record) => (
            <Switch
              defaultChecked={val ?? true}
              onChange={checked => ((record as SuperclusterInfo).enabled = checked)}
            />
          )}
        />
      </Table>
    </>
  );
};

const ClusterIdSearch = ({
  setData,
  titles,
}: {
  setData: Dispatch<SetStateAction<{ [id: string]: ClusterInfo }>>;
  titles: Titles;
}) => {
  const [searchInput, setSearchInput] = useState<string>();
  return (
    <Input
      key="clusterIdSearch"
      placeholder="Cluster ID"
      value={searchInput}
      onChange={event => {
        if (!event.target.value) {
          setData(titles.titles);
          setSearchInput(undefined);
        } else {
          const filteredData = Object.entries(titles.titles!)
            .filter(cluster => cluster[0].startsWith(event.target.value))
            .reduce<{ [id: string]: ClusterInfo }>((accum, [k, v]) => {
              // eslint-disable-next-line no-param-reassign
              accum[k] = v;
              return accum;
            }, {});
          setData(filteredData);
          setSearchInput(event.target.value);
        }
      }}
      style={{ width: 90 }}
    />
  );
};

const ClusterTable = ({
  loading,
  titles,
  chosenSet,
}: {
  loading: boolean;
  titles: Titles;
  chosenSet?: string;
}) => {
  const [data, setData] = useState<{ [id: string]: ClusterInfo }>(titles.titles);
  const sentiments = ["negative", "positive", "neutral"];

  const SuperClusterSelect = () => (
    <Select
      placeholder="Supercluster Filter"
      dropdownMatchSelectWidth={false}
      onChange={value => {
        if (value === "") {
          setData(titles.titles);
        } else if (value === "WITH_SUPERCLUSTER") {
          const filteredData = Object.entries(titles.titles!)
            .filter(cluster => !!cluster[1].supercluster)
            .reduce<{ [id: string]: ClusterInfo }>((accum, [k, v]) => {
              // eslint-disable-next-line no-param-reassign
              accum[k] = v;
              return accum;
            }, {});
          setData(filteredData);
        } else {
          const filteredData = Object.entries(titles.titles!)
            .filter(cluster => cluster[1].supercluster === value)
            .reduce<{ [id: string]: ClusterInfo }>((accum, [k, v]) => {
              // eslint-disable-next-line no-param-reassign
              accum[k] = v;
              return accum;
            }, {});
          setData(filteredData);
        }
      }}
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      showSearch
    >
      <Option key="blank" value="">
        All
      </Option>
      <Option key="with_supercluster" value="WITH_SUPERCLUSTER">
        HAS A SUPERCLUSTER
      </Option>
      {titles &&
        titles.superclusters &&
        Object.entries(titles.superclusters).map(superinfo => (
          <Option key={superinfo[0]} value={superinfo[0]}>
            {superinfo[1].title}
          </Option>
        ))}
    </Select>
  );

  const ClusterTitleSelect = () => (
    <Select
      style={{ paddingLeft: "80px" }}
      placeholder="Title Filter"
      dropdownMatchSelectWidth={false}
      onChange={value => {
        if (value === "") {
          setData(titles.titles);
        } else if (value === "NON_TITLED") {
          const filteredData = Object.entries(titles.titles!)
            .filter(cluster => !cluster[1].title)
            .reduce<{ [id: string]: ClusterInfo }>((accum, [k, v]) => {
              // eslint-disable-next-line no-param-reassign
              accum[k] = v;
              return accum;
            }, {});
          setData(filteredData);
        } else {
          const filteredData = Object.entries(titles.titles!)
            .filter(cluster => !!cluster[1].title)
            .reduce<{ [id: string]: ClusterInfo }>((accum, [k, v]) => {
              // eslint-disable-next-line no-param-reassign
              accum[k] = v;
              return accum;
            }, {});
          setData(filteredData);
        }
      }}
    >
      <Option key="blank" value="">
        All
      </Option>
      <Option key="titled" value="TITLED">
        Show Only Titled
      </Option>
      <Option key="non_titled" value="NON_TITLED">
        Show Only Non-Titled
      </Option>
    </Select>
  );

  useEffect(() => {
    if (titles) {
      setData(titles.titles);
    }
  }, [titles, chosenSet]);

  const numEnabled = Object.values(titles.titles).filter(
    t => !("enabled" in t) || t.enabled == true
  ).length;
  const numTotal = Object.keys(titles.titles).length;

  return (
    <>
      <Row>
        <Space>
          <SuperClusterSelect />
          <ClusterIdSearch setData={setData} titles={titles} />
          <ClusterTitleSelect />
          <div>
            {numEnabled} / {numTotal} enabled ({((numEnabled / numTotal) * 100).toFixed(1)}%)
          </div>
        </Space>
      </Row>
      <Col span={24}>
        <Table
          dataSource={data ? Object.values(data).filter(c => !(c.deprecated ?? false)) : []}
          pagination={{ pageSizeOptions: ["10", "25", "50", "100"], showSizeChanger: true }}
          size="small"
          loading={loading}
          className="selectable"
          rowKey={record => `${record.id}`}
        >
          <Column
            title="Super Cluster"
            key="superclusters"
            dataIndex="supercluster"
            width={80}
            render={(val, record) => (
              <Select
                style={{ width: 120 }}
                defaultValue={val}
                onChange={value => {
                  (record as ClusterInfo).supercluster = value !== "" ? value : undefined;
                }}
                dropdownMatchSelectWidth={false}
              >
                <Option value="">-</Option>
                {Object.values(titles.superclusters ?? []).map(sc => (
                  <Option key={sc.id + sc.title} value={sc.id}>
                    {sc.title ?? sc.id}
                  </Option>
                ))}
              </Select>
            )}
          />
          <Column title="Cluster" key="id" dataIndex="id" width={80} />
          <Column
            title="Sent"
            key="sent"
            dataIndex="sentiment"
            width={20}
            render={(val, record) => (
              <div
                style={{
                  border: "solid",
                  borderWidth: "1px",
                  borderColor:
                    val === "positive"
                      ? "green"
                      : val === "neutral"
                      ? "black"
                      : val === "negative"
                      ? "red"
                      : "transparent",
                }}
              >
                <Select
                  style={{ width: 80 }}
                  defaultValue={val}
                  onChange={value => {
                    (record as ClusterInfo).sentiment = value !== "" ? value : undefined;
                  }}
                  dropdownMatchSelectWidth={false}
                >
                  <Option value="">-</Option>
                  {sentiments.map(sc => (
                    <Option key={sc} value={sc}>
                      {sc}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          />
          <Column
            title="Title"
            key="title"
            dataIndex="title"
            render={(val, record) => (
              <Input
                defaultValue={val}
                onChange={e => {
                  (record as ClusterInfo).title = e.target.value;
                }}
                placeholder={(record as ClusterInfo).defaultTitle}
                spellCheck
              />
            )}
          />
          <Column
            title="Category"
            key="category"
            dataIndex="category"
            render={(val, record) => (
              <div>
                <Select
                  style={{ width: 100 }}
                  defaultValue={val}
                  onChange={value => {
                    (record as ClusterInfo).category = value !== "" ? value : undefined;
                  }}
                  dropdownMatchSelectWidth={false}
                >
                  <Option value="">-</Option>
                  {categories.map(category => (
                    <Option key={category} value={category}>
                      {category}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          />
          <Column
            title="Default"
            key="default"
            dataIndex="defaultTitle"
            width={40}
            render={(_, record) => (
              <CopyToClipboard text={(record as ClusterInfo).defaultTitle ?? ""}>
                <CopyOutlined />
              </CopyToClipboard>
            )}
          />
          <Column
            title="Emerging"
            key="emerging"
            dataIndex="emerging"
            width={60}
            render={(val, record) => (
              <Switch
                defaultChecked={val ?? false}
                onChange={checked => ((record as ClusterInfo).emerging = checked)}
              />
            )}
          />
          <Column
            title="Enabled"
            key="enabled"
            dataIndex="enabled"
            width={60}
            render={(val, record) => (
              <Switch
                defaultChecked={val ?? true}
                onChange={checked => ((record as ClusterInfo).enabled = checked)}
              />
            )}
          />
        </Table>
      </Col>
    </>
  );
};

// This is just to select a single report. Eventually this can be pulled out into a reusable component.
export const TitlesPageWrapper = ({
  reports,
  admin,
}: {
  reports?: ReportAPIResp;
  admin: boolean;
}) => {
  const { customer } = useCustomer();
  const params = useParams();

  const reportId = params ? params.reportId : customer.index.defaultReportSet;

  return reports && reportId ? (
    <>
      <Row key="spacer" style={{ margin: "0 24px 24px" }}>
        <Col />
      </Row>
      <AdminReportList reports={reports} admin={admin} />
      <div style={{ margin: "0 24px" }}>
        <TitlesPage titlesFileId={reports[reportId].titleId}></TitlesPage>
      </div>
    </>
  ) : (
    <div>Title Page error</div>
  );
};
