import { Col, Row, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getKPIs } from "../../reportApi";

import moment from "moment";

const { Title } = Typography;

// Retro Metro
const colors = [
  "#ea5545",
  "#27aeef",
  "#87bc45",
  "#ef9b20",
  "#ede15b",
  "#f46a9b",
  "#b33dc6",
  "#bdcf32",
  "#edbf33",
];

const days = 45;

interface KPIData {
  demos: { created_at: string; count: number }[];
  ingestion: ({
    [customer: string]: number;
  } & { created_at: string })[];
}

export const KPIPage = () => {
  const [data, setData] = useState<KPIData>();
  useEffect(() => {
    const f = async () => {
      setData(await getKPIs(days));
    };
    f();
  }, []);

  if (!data) {
    return <Spin spinning />;
  }

  return (
    <Row style={{ margin: "24px" }}>
      <Col span={24}>
        <Title level={1}>
          KPI Tracker <small>({days} days)</small>
        </Title>
        <Title level={3}>Demos per Week</Title>
        <ResponsiveContainer width={"100%"} height={150}>
          <BarChart data={data.demos}>
            <XAxis dataKey="created_at" tickFormatter={x => moment(x).format("YYYY-MM-DD")} />
            <YAxis />
            <Bar key="created_at" dataKey="count" />
            <ReferenceLine y={5} stroke="red" />
          </BarChart>
        </ResponsiveContainer>
        <Title level={3}>Ingestion Success</Title>
        <ResponsiveContainer width={"100%"} height={150}>
          <BarChart data={data.ingestion}>
            <XAxis dataKey="created_at" tickFormatter={x => moment(x).format("YYYY-MM-DD")} />
            <YAxis />
            {Object.keys(data.ingestion[0])
              .filter(c => c !== "created_at")
              .map((customer, idx) => (
                <Bar
                  key={customer}
                  dataKey={customer}
                  stackId="a"
                  fill={colors[idx % colors.length]}
                />
              ))}
            <Tooltip />
            <Legend />
          </BarChart>
        </ResponsiveContainer>
        <Title level={3}>Dataset Coverage</Title>
        <p>TODO</p>
        <Title level={3}>Monthly AWS Costs</Title>
        <p>TODO</p>
        <Title level={3}>MRR</Title>
        <p>TODO</p>
      </Col>
    </Row>
  );
};
