/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { UserOutlined, FrownOutlined } from "@ant-design/icons";
import { Button, Col, Menu, Row, Space } from "antd";
import { Auth } from "aws-amplify";
import React from "react";
import { BaseHeader } from "../components/Header";
import "../App.less";
import { BaseFooter } from "../components/Footer";

export const UnauthorizedUser = ({
  currentUser,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentUser: any;
}) => (
  <div>
    <BaseHeader>
      <Menu
        theme="dark"
        mode="horizontal"
        style={{
          lineHeight: "64px",
          float: "right",
        }}
      >
        <Menu.SubMenu
          key="unauth-user-submenu"
          title={
            <span>
              <UserOutlined />
              {currentUser?.attributes.email ?? "Spiral User"}
            </span>
          }
        >
          <Menu.ItemGroup title="Account:">
            <Menu.Item key="signout">
              <Button onClick={() => Auth.signOut()} danger>
                Sign Out
              </Button>
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu.SubMenu>
      </Menu>
    </BaseHeader>
    <Row align="middle" justify="center" style={{ minHeight: "50vh" }}>
      <Col span={8}>
        <div className="something-wrong">
          <Space direction="vertical" size="large">
            <div>
              <FrownOutlined style={{ fontSize: "60px" }} />
              <h1 style={{ fontSize: "40px" }}>Oops! Something went wrong.</h1>
              You are not authorized to view this page. Please contact customer support for
              assistance.
            </div>
            <div className="customer-buttons">
              <Space direction="vertical" size="large">
                <Button
                  href={`mailto:support@spiralup.co?subject=Dashboard Access Request
                  &body=Requesting Dashboard Access For Email: %0d%0a %0d%0a ${currentUser?.attributes.email}`}
                  type="primary"
                  size="large"
                >
                  Email Support
                </Button>
              </Space>
            </div>
          </Space>
        </div>
      </Col>
    </Row>
    <BaseFooter paddingTop="25vh" />
  </div>
);
