import React, { useEffect, useState } from "react";

import { Col, Row, Space, Spin, Table, Tag } from "antd";
import Column from "antd/lib/table/Column";
import { NavLink } from "react-router-dom";
import { User } from "../../indexTypes";
import { getUsers } from "../../reportApi";

export const UsersPage = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const f = async () => {
      setLoading(true);
      try {
        setUsers((await getUsers()).users);
      } finally {
        setLoading(false);
      }
    };
    f();
  }, []);

  return (
    <Row className="selectable" key="users" style={{ margin: "0 24px 12px" }}>
      <Col>
        <Spin spinning={loading}>
          <div>{users.length} Users</div>
          <Table dataSource={loading ? [] : users} pagination={false}>
            <Column title="User ID" dataIndex="id" />
            <Column
              title="Username"
              dataIndex="username"
              sorter={(a: User, b: User) => a.username.localeCompare(b.username)}
            />
            <Column title="Cognito ID" dataIndex="cognitoSub" />
            <Column
              title="Created"
              dataIndex="createdAt"
              render={val => val.toLocaleString()}
              sorter={(a: User, b: User) =>
                new Date(a.createdAt).getUTCMilliseconds() -
                new Date(b.createdAt).getUTCMilliseconds()
              }
              defaultSortOrder="descend"
            />
            <Column
              title="Customers"
              dataIndex="customers"
              render={(customers: string[]) => (
                <>
                  {customers.map(c => (
                    <Tag key={c}>{c}</Tag>
                  ))}
                </>
              )}
            />
            <Column
              title="Edit"
              render={user => <NavLink to={{ pathname: user.cognitoSub }}>Edit</NavLink>}
            />
          </Table>
        </Spin>
      </Col>
    </Row>
  );
};
