import React from "react";
import { useParams } from "react-router";
import { ReportsViewerWrapper } from "../components/ReportsViewer";
import { isAdmin, useCurrentUser } from "../hooks";
import { ReportAPIResp } from "../indexTypes";

export const MainPage = ({ reports }: { reports?: ReportAPIResp; reportId?: string }) => {
  const params = useParams();
  const user = useCurrentUser();
  const userIsAdmin = isAdmin(user);
  return (
    <div>
      {reports && params.reportId && params.reportId in reports ? (
        <ReportsViewerWrapper report={reports[params.reportId]} admin={userIsAdmin} />
      ) : (
        <div>Report Missing</div>
      )}
    </div>
  );
};
