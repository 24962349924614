import { Col, Radio, Row } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { useCustomer } from "../hooks";
import { ReportAPIResp } from "../indexTypes";

export const AdminReportList = ({
  reports,
  admin,
}: {
  reports?: ReportAPIResp;
  admin: boolean;
}) => {
  const { customer } = useCustomer();

  const navigate = useNavigate();
  const params = useParams();
  const reportId = params.reportId;

  const swapReports = (chosenReport: string) => {
    navigate(`../${chosenReport}`, { replace: true });
  };

  return (
    <div>
      {admin && reports && Object.keys(reports).length > 1 && (
        <Row style={{ margin: "0 24px 12px" }}>
          <Col>
            <Radio.Group
              value={reportId}
              defaultValue={customer.index.defaultReportSet}
              size="large"
              onChange={e => {
                swapReports(e.target.value);
              }}
            >
              {Object.entries(reports).map(([key, report]) => (
                <Radio.Button key={key} value={key}>
                  {`${report.name}:${report.urlHash}`}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Col>
        </Row>
      )}
    </div>
  );
};
