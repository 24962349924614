import React from "react";
import { SidebarItem, SidebarSubMenu } from "./indexTypes";

const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*(@|\sat\s)(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?(\.|\sdot\s))+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const DOB = new RegExp(
  [
    "(?:\\d{1,2}[-/\\s]\\d{1,2}[-/\\s]'?\\d{2,4})",
    "(?:\\d{2,4}[-/\\s]\\d{1,2}[-/\\s]\\d{1,2})",
    "(?:(?:January|February|March|April|May|June|July|August|September|October|November|December|Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sept|Sep|Oct|Nov|Dec)[\\s-/,]*?\\d{1,2}(?:\\s)*(?:rd|th|st)?(?:\\s)*[-/,]?(?:\\s)*'?\\d{2,4})",
    "(?:\\d{1,2}(?:\\s)*(?:rd|th|st)?(?:\\s)*(?:January|February|March|April|May|June|July|August|September|October|November|December|Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sept|Sep|Oct|Nov|Dec)(?:\\s)*?[-/,]?(?:\\s)*'?\\d{2,4})",
  ].join("|"),
  "i"
);
const cc = /\b(?:d{4}[ -]?){3}(?=d{4}\b)/;
const ph = /\b(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}\b/;

export const stripPii = (text: string) =>
  text
    .replace(emailRegex, "{{EMAIL}}")
    .replace(DOB, "{{DOB}}")
    .replace(cc, "{{CARD}}")
    .replace(ph, "{{PHONE}}")
    .replace("{{DOB}}", "{{DATE}}");

export const select = <T,>(arr: T[], idxs: number[]) => idxs.map(i => arr[i]).filter(z => !!z);

export const displayName = (str: string, displayNames?: { [name: string]: string }) => {
  if (displayNames && str in displayNames) return displayNames[str];
  if (str === undefined || str === null) return "None";
  if (["sms", "nps", "csat", "dsat", "fcr"].includes(str.toLowerCase())) return str.toUpperCase();
  if (str.trim() === "") return "N/A";
  return str
    .split(" ")
    .map(word => {
      const w = word.replaceAll("_", " ").replaceAll(/(\w)-(\w)/g, "$1 $2");
      return w === w.toUpperCase()
        ? w
        : w.replace(/\w\S*/g, t => t.charAt(0).toUpperCase() + t.substr(1).toLowerCase());
    })
    .join(" ");
};

export const isDefined = <T,>(item: T | null | undefined): item is T => !!item;

export const sum = (a: number, b: number) => a + b;

export const truncate = (str: string, len: number, wrapInSpan = true) => {
  const shortened = str.substr(0, len - 1) + (str.length > len ? "..." : "");
  return wrapInSpan ? <span title={str}>{shortened}</span> : shortened;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSidebarSubMenu = (obj: any): obj is SidebarSubMenu => {
  // eslint-disable-next-line id-blacklist
  return obj.items !== undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSidebarItem = (obj: any): obj is SidebarItem => {
  // eslint-disable-next-line id-blacklist
  return obj.reportSet !== undefined;
}
