import { CurveType } from "recharts/types/shape/Curve";

export interface ReportWindow {
  start: string;
  end: string;
  interval: string;
  periods: number;
}

export interface Filter {
  field: string;
  values: string[];
}

export interface Filters {
  [name: string]: {
    displayName?: string;
    required?: boolean;
    discrete?: boolean;
    values: {
      [value: string]: {
        displayName?: string;
      };
    };
  };
}

export type SidebarItem = {
  /**
   * boolean: controls whether the sidebar item is only visible to admins or not
   */
  adminOnly?: boolean;

  /**
   * report set associated with this sidebar item
   */
  reportSet: string;

  /**
   * display name for the sidebar item
   */
  displayName: string;

  /**
   * boolean: is the demo active or not
   */
  activeDemo?: boolean;

  /**
   * boolean: is the sidebar item disabled or not
   */
  disabled?: boolean;

  /**
   * SVG string for the icon we want to display beside the sidebar item
   */
  iconString?: string;
};

export type SidebarSubMenu = {
  /**
   * boolean: controls whether the sidebar sub menu is only visible to admins or not
   */
  adminOnly?: boolean;

  /**
   * key for the sidebar sub menu
   */
  key: string;

  /**
   * display name for the sidebar sub menu
   */
  displayName: string;

  /**
   * list of sidebar items for the sub menu
   */
  items: SidebarItem[];

  /**
   * SVG string for the icon we want to display beside the sidebar sub menu
   */
  iconString?: string;
};

export type TrendColors = {
  up?: string;
  down?: string;
  flat?: string;
};

export type MenuAllItems = {
  key: string;
  label: string;
  children: any[];
};

export type Customer = {
  customerIndexJson: CustomerIndex;
  id: string;
  reports: number[];
  users: number[] | User[];
};

export type CustomerIndex = {
  /**
   * The display name
   */
  displayName: string;
  /**
   * The name of the file. Changing this currently takes 24 hours to update in production due to caching.
   */
  logo: string;
  /**
   * CSS styling that gets applied to the logo.
   */
  logoStyle?: object;
  /**
   * Adjusts the text: "view in __CRM__" on the evidence card
   */
  crmName: string;
  /**
   * The dashboard a user will land on if not specified in the url. This needs to exist as a `reportSet` in the
   * `reportSetHierarchy` or bad things.
   */
  defaultReportSet: string;
  /**
   * This defines the sidebar. It can be a list of items or "folders" of items
   * 
   * sub properties:
   * SidebarItem
   * - adminOnly - boolean: controls whether the sidebar sub menu is only visible to admins or not
   * - reportSet - report set associated with this sidebar item
   * - displayName - display name for the sidebar item
   * - activeDemo - boolean: is the demo active or not
   * - disabled - boolean: is the sidebar item disabled or not
   * - iconString - SVG string for the icon we want to display beside the sidebar item
   * OR
   * SidebarSubMenu
   * - adminOnly - boolean: controls whether the sidebar sub menu is only visible to admins or not
   * - key - key for the sidebar sub menu
   * - displayName - display name for the sidebar sub menu
   * - items - list of sidebar items for the sub menu
   * - iconString - SVG string for the icon we want to display beside the sidebar sub menu
   */
  reportSetHierarchy: (SidebarItem | SidebarSubMenu)[];
  /**
   * List of email domain strings for this customer. Anybody who signs up with an email domain in this list will be
   * added to this customer ONLY AT SIGNUP. Modifying this list will not make any retroactive changes.
   */
  email_domains: string[];
  /**
   * Optional - set the colors with "up" and "down"
   */
  trendColors?: TrendColors;
};

export type Period = "day" | "week" | "month" | "year";

export type DisplayDateFormat = {
  display: string;
  endDateFormat?: string;
  startDateFormat?: string;
  snapTo?: {
    direction: "start" | "end";
    unit: Period | "isoWeek";
  };
};

export type Sentiment = "positive" | "neutral" | "negative";

export type Category = "product" | "cx";

export type ReportDatasetInclude = { uuid: string; filters?: Filter[] };

export type ReportIndexAudit = {
  id: number;
  reportIndexJson: ReportIndex;
  updatedBy: string;
  updatedAt: string;
  userName: string;
};

export type keyList = {
  key: string;
}[];

export type EvidenceCardConfig = {
  starsField?: keyList;
  starsText?: keyList;
  crmUrlField?: keyList;
  values?: keyList;
  keyValues?: {
    key: string;
    displayName?: string;
  }[];
  showSource: boolean;
};

export const REPORT_TITLE_TEMPLATE = "{REPORT_TITLE}";

export type ReportIndex = {
  /**
   * Controls the trending widget above the issues
   *
   * sub properties:
   * - enabled: boolean (default false)
   * - sortBy: "latest" | "overall" | "trend" | "increasing" | "decreasing" (default: "trend")
   * - topN: number, how many to show (default: 5)
   * - sentiment: "positive" | "negative" | "neutral" (default: "negative")
   */
  trendingWidget?: {
    enabled?: boolean;
    sortBy?: SortByOptions;
    topN?: number;
    sentiment?: Sentiment;
  };
  /**
   * array of datasets to include. controls which data is included in the report
   */
  datasets?: ReportDatasetInclude[];

  /**
   * set a static fixed end date for the report in YYYY-MM-DD format. the right side of the graph will end on this date.
   * if unset, the dashboard will automatically calculate the latest date we have data for
   */
  endDate?: string;

  /**
   * set dashboard to use the latest date of all data in a given report
   */
  useLatestDate?: boolean;

  /**
   * sets the granularity of the graphs. options are "day", "week", "month", "year"
   */

  period: Period;
  /**
   * number of "period" to include in the report. this calculates the start date and controls the length of the report.
   * units are whatever "period" is set to
   */

  periodLength: number;
  /**
   * similar to periodLength. this combined with period determined the data to aggregate when calculating the latest
   * value.
   */

  latestLength: number;
  /**
   * sets the minimum number of evidences required to display evidences. if there are fewer, a "not enough data" message will be shown in the evidences section
   * defaults to 3
   */
  hideEvidencesBelow?: number;

  /**
   * cap the maximum number of evidences to show. if there are more, they are hidden. this also disables the "Show More" button for evidences
   */
  maxNumberOfEvidences?: number;

  /**
   * sets the default "Sort By". options are "overall" or "latest"
   */
  defaultPercent?: "overall" | "latest";

  /**
   * Sets the text in the "date picker", only if date adjustment is enabled on the view
   */
  displayDateFormat?: DisplayDateFormat;

  /**
   * sets the legend text beneath the top overview graph (e.g. "Google Play & App Store Reviews")
   *
   * use the template {REPORT_TITLE} as a placeholder in the legend text for this report's display title from the sidebar
   */
  overviewLegendText: string;

  /**
   * Configures the display of the evidence card and evidence modal. All possible configuration are in the format of
   * "<nameOfOption>": [{"key":"<fieldName>"}]. This "list of objects" style configuration is so that multiple fields
   * can be added and the first or all valid fields will show up for a given evidence. This is particular important for
   * mixed source views, where some evidences will have "user" and others will have "author" and in that case both
   * fields can be added and the evidences will only show the field that actually has data for that evidence.
   *
   * Current supported configurations are: starsField, starsText (previously reviewAuthorFieldName), crmUrlField
   * values - list of items where only the values will be displayed, eg: "author | channel"
   * keyValues - same as values but will display the field name, eg: "Impressions: 100". Supports `displayName` for the fieldname
   * showSource - <boolean> if true, displays the dataset that the evidence came from. DisplayName must also be set on the dataset
   */
  evidenceCard?: EvidenceCardConfig;

  /**
   * static fallback URL to use for "View in CRM" link if no real URL is available (e.g. )
   */
  defaultCRMUrl?: string;

  /**
   * set a fallback sentiment for clusters that aren't manually labeled during titling
   * options are "positive", "negative", "neutral"
   */
  defaultSentiment?: Sentiment;

  /**
   * threshold (in degrees) above which consider we consider a cluster's trend line to be moving upward or downward.
   *
   * e.g. a cluster's trend line's slope is at an angle of 15 degrees, and sentimentThreshold for its report is set to
   * 10 degrees. The cluster is then considered as trending upward. If it is a negative sentiment cluster, this will be
   * presented as a negative color on dashboard, if it is a positive cluster, the graph will use a positive color.
   */
  sentimentThreshold?: number;

  /**
   * set the default analyzeBy that should be selected on load. value should be a metadata field name and match an entry in `analyzeByOptions`
   */
  defaultAnalyzeBy?: string;

  /**
   * boolean, whether to show the sentiment filter
   */
  showSentimentFilter?: boolean;

  /**
   * set a default sentiment to filter by. options are "positive", "negative", "neutral"
   */
  sentimentToShowByDefault?: Sentiment;

  /**
   * boolean, whether to show "Group Issues" box (default: false)
   */
  showSuperclustersToggle?: boolean;

  /**
   * boolean, whether superclusters are grouped by default (default: true)
   */
  superclustersOnByDefault?: boolean;

  /**
   * settings to control the back/forward buttons (TODO more description)
   */
  datePicker?: {
    amount: number;
    unit: Period;
    snapTo?: {
      direction: "start" | "end";
      unit: Period;
    };
  };

  /**
   * key-value map of raw names to display names. used to control renaming values in dropdowns, pie charts, evidence
   * metadata, etc
   */
  displayNames?: {
    [name: string]: string;
  };

  /**
   * The static configuration of the "filter by" drop down. This is driven by metadata values and _should_ be checked
   * against the results that will come back from the same dashboards "analyze_by"
   */
  filters?: {
    [name: string]: {
      displayName?: string;
      required?: boolean;
      discrete?: boolean;
      values: {
        [value: string]: {
          displayName?: string;
        };
      };
    };
  };

  /**
   * list of metadata fields to show in "Analyze By" dropdown
   */
  analyzeByOptions?: string[];

  /**
   * max number of slices to show in pie charts. if there are more slices, they will be grouped into aan "Other" category
   */
  analyzeByLimit?: number;

  /**
   * boolean, whether to show the back/forward date buttons
   */
  showDateButtons?: boolean;

  /**
   * setting to control how many labels on the x-axis of graphs
   */
  minTickGap?: number;

  /**
   * format for x-axis date labels (e.g. "D MMM 'YY")
   */
  xTickFormat?: string;

  /**
   * changes the type of curve interpolation. defaults to "monotone", but demos use "basis".
   * n.b.: basis is a bad choice as it does not go through each data point. this makes the graph lies to you
   */
  areaCurveType?: CurveType;
};

export type Report = {
  id: string;
  titleId: string; // id of the title file
  name: string;
  urlHash: string;
  reportIndexJson: ReportIndex;
};

export type ReportAPIResp = {
  [reports: string]: Report;
};

export interface User {
  id: number;
  username: string;
  cognitoSub: string;
  createdAt: string;
  customers: string[];
}

export type APIError = {
  error: string;
  description: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAPIError(resp: any): resp is APIError {
  return resp.error && resp.status;
}

export interface Dataset {
  id: string;
  uuid: string;
  name: string;
  foo: string;
  parserName: string;
  classifierModelId: string;
  customerIds: [string];
}

export interface DatasetFilterCounts {
  [filter: string]: {
    [filterValue: string]: number;
  };
}

export interface TypeDocComment {
  kind?: string;
  text?: string;
}
export interface TypeDocInfo {
  name?: string;
  childen?: TypeDocInfo[];
  comment?: {
    summary?: TypeDocComment[];
  };
}

export type SortByOptions = "latest" | "overall" | "trend" | "increasing" | "decreasing";
