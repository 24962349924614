import { Col, Progress, Row } from "antd";
import React, { ChangeEvent, useEffect, useState } from "react";

interface ClusterCandidates {
  [id: string]: string[];
}

const FilterView = ({ candidates }: { candidates: ClusterCandidates }) => {
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [selected, setSelected] = useState<string[]>([]);
  const keys = Object.keys(candidates);

  useEffect(() => {
    setMaxPage(Math.max(page, maxPage));
  }, [page, maxPage]);

  useEffect(() => {
    const keyHandler = (e: KeyboardEvent) => {
      if (e.key == "ArrowRight") {
        if (page < keys.length - 1) setPage(page + 1);
      } else if (e.key == "ArrowLeft") {
        if (page > 0) setPage(page - 1);
      } else if (e.key == "a") {
        const cluster = keys[page];
        if (selected.includes(cluster)) {
          setSelected([...selected].filter(x => x != cluster));
        } else {
          setSelected([...selected, cluster]);
        }
      }
    };
    window.addEventListener("keyup", keyHandler);
    return () => {
      window.removeEventListener("keyup", keyHandler);
    };
  }, [keys, selected, page]);

  return (
    <>
      <Row style={{ margin: "0 24px" }}>
        <Col span={12}>
          <div>Count: {keys.length}</div>
          <div>
            <Progress percent={Math.round((maxPage / keys.length) * 1000) / 10} />
          </div>
          <br />
        </Col>
      </Row>
      <Row style={{ margin: "0 24px" }}>
        <Col>
          <div>
            Cluster: {keys[page]} ({page} / {keys.length}) -{" "}
            {selected.includes(keys[page]) ? "✅" : "❌"}
          </div>
          <ul style={{ columns: 2 }}>
            {candidates[keys[page]].map(e => (
              <li key={e}>{e}</li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row style={{ margin: "0 24px", maxWidth: "100%" }}>
        <Col span={24}>
          <div>Selected:</div>
          <div className="selectable" style={{ wordWrap: "break-word" }}>
            {selected.length > 0 ? JSON.stringify(selected) : ""}
          </div>
        </Col>
      </Row>
    </>
  );
};

export const ClusterFilterPage = () => {
  const [fileContent, setFileContent] = useState<ClusterCandidates>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    if (!e.target.files) return;
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      if (!e.target) return;
      const content = e.target.result as string;
      console.log(content);
      setFileContent(JSON.parse(content));
    };
  };

  if (!fileContent) {
    return (
      <>
        <h1>Upload Json file - Example</h1>

        <input type="file" onChange={handleChange} />
      </>
    );
  } else {
    return <FilterView candidates={fileContent} />;
  }
};
