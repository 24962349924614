import Icon, { LayoutOutlined, LockOutlined } from "@ant-design/icons";
import { Button, Menu, MenuProps } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import React from "react";
import { Link, NavLink, useMatch } from "react-router-dom";
import { CustomerUIModel } from "../hooks";
import { ReportAPIResp, SidebarItem, SidebarSubMenu } from "../indexTypes";
import { isDefined, isSidebarSubMenu } from "../utils";

// Helper for auto-expanding the submenus
function getSubmenuExpandPath(reportHierarchy: (SidebarSubMenu | SidebarItem)[], reportId: string) {
  if (!reportHierarchy) {
    return ["subReportSets"];
  }
  // Find the currently selected report in the reportHierarchy JSON and
  const vals = reportHierarchy
    .filter(isSidebarSubMenu)
    .filter(val => val.items.filter(item => item.reportSet === reportId).length > 0)
    .map(val => val.key);

  // Always expand the "All Channels" menu item
  return ["subReportSets"].concat(vals);
}

const getIcon = (iconString?: string) => {
  if (!iconString) {
    return;
  }
  const IconSVG = () => (
    <img
      style={{ height: "1em", width: "1em", marginTop: "-2px" }}
      alt=""
      src={`data:image/svg+xml;base64,${iconString}`}
    />
  );
  return <Icon component={IconSVG} />;
};

const convertToMenu = (
  item: SidebarItem | SidebarSubMenu,
  isAdmin: boolean
): ItemType | undefined => {
  if (item.adminOnly && !isAdmin) {
    return;
  }
  if (isSidebarSubMenu(item)) {
    return {
      key: item.key,
      label: item.displayName,
      icon: getIcon(item.iconString) ?? <LayoutOutlined />,
      children: item.items.map(i => convertToMenu(i, isAdmin)).filter(isDefined),
    };
  } else {
    const labelContents = (
      <>
        {item.displayName}{" "}
        {item.adminOnly && (
          <span>
            &nbsp;
            <LockOutlined />
          </span>
        )}
      </>
    );
    return {
      key: item.reportSet,
      danger: item.adminOnly,
      icon: getIcon(item.iconString),
      disabled: item.disabled,
      label: item.disabled ? (
        labelContents
      ) : (
        <NavLink to={{ pathname: `/dashboards/${item.reportSet}` }}>{labelContents}</NavLink>
      ),
    };
  }
};

// Convenience function to recurse through the reportHierarchy and return a single item for a given reportId
const getReportById = (
  reportHierarchy: (SidebarItem | SidebarSubMenu)[],
  reportId: string
): SidebarItem | undefined => {
  let foundReport;
  reportHierarchy.forEach(report => {
    if (isSidebarSubMenu(report)) {
      foundReport = getReportById(report.items, reportId);
    } else if (report.reportSet === reportId) {
      foundReport = report;
    }
  });

  return foundReport;
};

export const SpiralMenu = ({
  customer,
  unsetCustomer,
  admin,
  reports,
}: {
  customer: CustomerUIModel;
  unsetCustomer: () => void;
  admin: boolean;
  reports?: ReportAPIResp;
}) => {
  const reportHierarchy = customer.index.reportSetHierarchy;
  function getReportId() {
    // Check if the report_id is in the URL, but ignore the value if it's not a report owned by that customer
    const match = useMatch("/dashboards/:reportId");
    if (match) {
      const { reportId = "" } = match.params as { reportId: string };
      if (reportId && reports && reportId in reports) {
        return reportId;
      }
    }
    return customer.index.defaultReportSet ?? undefined;
  }
  const chosenReport = getReportId();

  const adminMenuItems: MenuProps["items"] = [
    {
      key: "admin",
      label: "Admin Pages",
      children: [
        { key: "admin-demo", label: <NavLink to="/admin/demos">Demo Manager</NavLink> },
        {
          key: "admin-customer",
          label: (
            <NavLink to={`/admin/customers/${customer.id}/customer`}>Customer Manager</NavLink>
          ),
        },
        { key: "admin-users", label: <NavLink to="/admin/users">Users</NavLink> },
        { key: "admin-datasets", label: <NavLink to="/admin/datasets">Datasets</NavLink> },
        { key: "admin-kpis", label: <NavLink to="/admin/kpis">KPIs</NavLink> },
        {
          key: "admin-unset",
          label: (
            <Link to="/">
              <Button onClick={() => unsetCustomer()}>UnsetCustomer</Button>
            </Link>
          ),
        },
      ],
    },
  ];

  const menuItems: ItemType[] = [];

  if (reports) {
    menuItems.push(...reportHierarchy.map(e => convertToMenu(e, admin)).filter(isDefined));
  }
  if (admin) {
    menuItems.push(...adminMenuItems);
  }

  return reports ? (
    <Menu
      id="sidebarOptions"
      key="sidebarOptions"
      mode="inline"
      defaultOpenKeys={getSubmenuExpandPath(reportHierarchy, chosenReport)}
      defaultSelectedKeys={[chosenReport]}
      items={menuItems}
    />
  ) : (
    <></>
  );
};
