import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth, Hub } from "aws-amplify";
import React, {
  createContext,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { CustomerIndex } from "./indexTypes";
import { customerIndexes } from "./reportApi";

export type CustomerUIModel = {
  id: string;
  index: CustomerIndex;
};

export const isAdmin = (user: CognitoUser | null) => {
  const userPayload = user?.getSignInUserSession()?.getAccessToken().decodePayload();
  if (!userPayload || !userPayload["cognito:groups"]) {
    return false;
  }
  return userPayload["cognito:groups"].includes("admin") ?? false;
};

export const useCurrentUser = () => {
  const [user, setUser] = useState<CognitoUser | null>(null);

  useEffect(() => {
    async function updateUser() {
      try {
        setUser(await Auth.currentAuthenticatedUser());
      } catch {
        setUser(null);
      }
    }
    Hub.listen("auth", updateUser);
    updateUser();
    return () => Hub.remove("auth", updateUser);
  }, []);

  return user;
};

export const useCustomerGroups = () => {
  const [customer, setCustomer] = useState<CustomerUIModel>();
  const [customers, setCustomers] = useState<CustomerUIModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const currentUser = useCurrentUser();
  const userId = currentUser?.getUsername();

  const unsetCustomer = useCallback(() => {
    setCustomer(undefined);
    localStorage.removeItem("chosenCustomerId");
  }, [setCustomer]);

  useEffect(() => {
    async function getJson() {
      setIsLoading(true);

      setCustomers(await customerIndexes());
      setIsLoading(false);
    }

    if (userId) {
      getJson();
    }
  }, [userId]);

  useEffect(() => {
    if (customers.length === 1) {
      setCustomer(customers[0]);
    }
  }, [customers]);

  return { customer, customers, setCustomer, unsetCustomer, isLoading };
};

type CustomerInfo = {
  customer: CustomerUIModel;
  customers: CustomerUIModel[];
  setCustomer: React.Dispatch<SetStateAction<CustomerUIModel | undefined>>;
  unsetCustomer: () => void;
};

export const customerContext = createContext(undefined as any);
export const useCustomer = () => useContext(customerContext) as CustomerInfo;
