import { PlusOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, InputRef, notification, Row, Select, Spin, Tag } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { Dataset, DatasetFilterCounts, Filters, isAPIError } from "../../indexTypes";
import { getDataset, getDatasetFilter } from "../../reportApi";

export const FilterTags = ({
  filter,
  setFilter,
  text,
}: {
  filter: string[];
  setFilter: Dispatch<SetStateAction<string[]>>;
  text: string;
}) => {
  // const [filter, setFilter] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const inputRefOmit = useRef<InputRef>(null);
  const [inputVisible, setInputVisible] = useState<boolean>(false);

  const removeTag = (removedTag: string) => {
    const newTags = filter.filter(tag => tag !== removedTag);
    setFilter(newTags);
  };

  const handleNewTags = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };
  const createNewTag = () => {
    if (inputValue && filter.indexOf(inputValue) === -1) {
      setFilter([...filter, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };
  const tagMap = (tag: string) => {
    const tagElem = (
      <Tag
        closable
        onClose={e => {
          e.preventDefault();
          removeTag(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };

  useEffect(() => {
    if (inputVisible) {
      inputRefOmit.current?.focus();
    }
  }, [inputVisible]);

  const showInput = () => {
    setInputVisible(true);
  };

  return (
    <Form.Item label={text} style={{ padding: "10px" }}>
      {inputVisible && (
        <Input
          ref={inputRefOmit}
          type="text"
          size="small"
          style={{ width: 78 }}
          value={inputValue}
          onChange={handleNewTags}
          onBlur={createNewTag}
          onPressEnter={createNewTag}
        />
      )}
      {!inputVisible && (
        <Tag onClick={showInput} className="site-tag-plus">
          <PlusOutlined /> Add Filter
        </Tag>
      )}
      {filter.map(f => tagMap(f))}
    </Form.Item>
  );
};

export const DatasetPage = () => {
  const [dataset, setDataset] = useState<Dataset>();
  const [datasetFilter, setDatasetFilters] = useState<DatasetFilterCounts>();
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(true);
  const [omitFilter, setOmitFilter] = useState<string[]>([]);
  const [includeFilter, setIncludeFilter] = useState<string[]>([]);
  const [filterSize, setFilterSize] = useState(10);
  const [filterKey, setFilterKey] = useState<string>();
  const [filterValue, setFilterValue] = useState<string>();

  const { datasetUuid } = useParams();

  // Initial query on page load to get the name and other top level info of the dataset
  useEffect(() => {
    if (!datasetUuid) {
      return;
    }
    const f = async () => {
      setLoading(true);
      const resp = await getDataset(datasetUuid);
      if (!isAPIError(resp)) {
        setDataset(resp);
      }
    };

    f();
  }, [datasetUuid]);

  // Gets the filter counts, called each time the filters are changed
  useEffect(() => {
    const controller = new AbortController();
    if (!datasetUuid) {
      return;
    }
    const f = async () => {
      setLoading(true);
      const resp = await getDatasetFilter(
        datasetUuid,
        filterKey,
        filterValue,
        omitFilter,
        includeFilter,
        filterSize
      );
      if (isAPIError(resp)) {
        console.error(resp);
        notification.error({ message: `Bad filter query. ${resp.description}`, duration: 5 });
        return;
      }
      setDatasetFilters(resp);
      setLoading(false);
    };

    f();
    return () => {
      controller.abort();
    };
  }, [datasetUuid, omitFilter, includeFilter, filterSize, filterKey, filterValue]);

  // translates the filter counts to the copy/pastable filter text on the right
  useEffect(() => {
    if (!datasetFilter) {
      return;
    }
    const filterObj: Filters = {};
    for (const filter in datasetFilter) {
      filterObj[filter] = { discrete: false, values: {} };
      filterObj;
      for (const val in datasetFilter[filter]) {
        filterObj[filter].values[val] = {};
      }
    }
    setFilterText(JSON.stringify(filterObj, null, 2));
  }, [datasetFilter]);

  const handleSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterSize(Number(e.target.value));
  };
  const handleFilterTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFilterText(e.target.value);
  };

  return (
    <>
      {dataset ? (
        <div className="selectable">
          <h1>{dataset.name}</h1>
          <Row>
            <Col offset={4} span={16}>
              <pre className="admin-text-box">{JSON.stringify(dataset, null, 2)}</pre>
            </Col>
          </Row>
          <Divider orientation="left">Filters</Divider>
          <Row>
            <Col className="admin-text-box" offset={1} span={22}>
              <Input.Group compact={true}>
                <Form.Item label="Size:" style={{ padding: "10px" }}>
                  <Input
                    onChange={handleSizeChange}
                    value={filterSize}
                    style={{ width: "100px" }}
                    placeholder="size"
                  ></Input>
                </Form.Item>

                <Form.Item label="Filter" style={{ padding: "10px", width: "300px" }}>
                  <Select onChange={val => setFilterKey(val)}>
                    {datasetFilter &&
                      Object.keys(datasetFilter).map(k => (
                        <Select.Option key={k}>{k}</Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item style={{ padding: "10px", width: "300px" }}>
                  {datasetFilter && (
                    <Select onChange={val => setFilterValue(val)}>
                      {filterKey &&
                        filterKey in datasetFilter &&
                        Object.keys(datasetFilter[filterKey]).map(k => (
                          <Select.Option key={k}>{k}</Select.Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </Input.Group>
              <Input.Group compact={true}>
                <FilterTags
                  filter={omitFilter}
                  setFilter={setOmitFilter}
                  text="Values to omit"
                ></FilterTags>
                <FilterTags
                  filter={includeFilter}
                  setFilter={setIncludeFilter}
                  text="Only show"
                ></FilterTags>
              </Input.Group>
            </Col>
          </Row>
          <Spin spinning={loading}>
            <Row>
              <Col span={10} offset={1}>
                <h1>Raw Counts</h1>
                <pre className="admin-text-box">{JSON.stringify(datasetFilter, null, 2)}</pre>
              </Col>
              <Col span={1}></Col>
              <Col span={10}>
                <h1>Filter String</h1>
                <textarea
                  className="admin-text-box"
                  onChange={handleFilterTextChange}
                  style={{ width: "100%", height: "100%" }}
                  value={filterText}
                ></textarea>
              </Col>
            </Row>
          </Spin>
        </div>
      ) : null}
    </>
  );
};
